<template>
  <div class="card p-4 row">
    <div class="col-12">
      <h4 style="color: #428a94; font-weight: bold">
        Hasil Pemeriksaan Lab Patologi Klinis
      </h4>
      <div class="mt-4">
        <div class="card">
          <div class="card-body" style="background-color: #f6feff">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-tabs :default-active-key="1">
        <a-tab-pane key="1" tab="Hasil Pemeriksaan">
          <div
            class="px-4 py-3 mt-4"
            style="border-radius: 8px"
            v-for="(master, index) in masterComponents"
            :key="index"
          >
            <h5 style="font-size: 16px">{{ master.MasterExamination.name }}</h5>
            <div>
              <a-upload
                :file-list="fileList"
                :max-count="1"
                :before-upload="() => false"
                name="file"
                @change="
                  (info) =>
                    handleChange(
                      info,
                      master.MasterExamination.ExaminationComponents,
                      index,
                    )
                "
              >
                <a-button
                  type="secondary"
                  class="mt-2"
                  style="width: 196px; border-radius: 26px"
                >
                  <img
                    src="resources/images/stars-icon.svg"
                    alt="Icon"
                    style="width: 20px; height: 20px; margin-right: 8px"
                    v-if="!loadingAutomationInput"
                  />
                  <a-spin v-else>
                    <a-icon
                      slot="indicator"
                      type="loading"
                      style="font-size: 18px"
                      class="mr-3 mb-0"
                      spin
                    />
                  </a-spin>
                  Auto-Input Nilai Hasil</a-button
                >
                <a-tooltip placement="right">
                  <template #title>
                    <span
                      >Unggah foto atau dokumen hasil pemeriksaan untuk
                      memasukkan nilai secara otomatis. Periksa kembali hasil
                      auto-input untuk memastikan akurasi sebelum
                      menyimpan.</span
                    >
                  </template>
                  <InfoCircleOutlined class="ml-3" />
                </a-tooltip>
              </a-upload>
            </div>
            <div class="row mt-4">
              <div class="col-3">
                <h6>Komponen Pemeriksaan</h6>
              </div>
              <div class="col-3">
                <h6>Nilai Hasil</h6>
              </div>
              <div class="col-2">
                <h6>Nilai Rujukan</h6>
              </div>
              <div class="col-2">
                <h6>Nilai Kritis Rendah</h6>
              </div>
              <div class="col-2">
                <h6>Nilai Kritis Tinggi</h6>
              </div>
            </div>
            <div
              v-for="(item, idx) in master.MasterExamination
                .ExaminationComponents"
              :key="idx"
            >
              <!-- HEADER SECTION  -->

              <div>
                <div
                  class="row mt-4"
                  v-if="
                    item.ExaminationAnswers &&
                    item.resultType !== 'Quantitative' &&
                    item.resultType !== 'Narrative'
                  "
                >
                  <div class="col-3">{{ item.name }}</div>
                  <div class="col-4">
                    <a-select
                      ref="select"
                      v-model="item.resultValue"
                      style="width: 300px"
                    >
                      <a-select-option value="" selected
                        >Pilih Hasil</a-select-option
                      >
                      <a-select-option
                        v-for="(answer, idx) in item.ExaminationAnswers"
                        :key="idx"
                        :value="answer.answerList"
                        >{{ answer.answerList }}</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
                <div
                  class="row mt-4"
                  v-else-if="item.resultType === 'Narrative'"
                >
                  <div class="col-3">{{ item.name }}</div>
                  <div class="col-4">
                    <a-textarea
                      placeholder="Ketik Hasil Pemeriksaan"
                      v-model="item.resultValue"
                      :rows="4"
                      style="width: 300px"
                    />
                  </div>
                </div>
                <div
                  class="row mt-4"
                  style="position: relative"
                  v-for="(
                    indicator, indexIndicator
                  ) in item.ExaminationIndicators"
                  :key="indexIndicator"
                  v-else
                >
                  <!-- INI INDICATOR ROW -->
                  <div class="col-12 my-2 col-xl-3">
                    <p>{{ item.name }}</p>
                  </div>

                  <div class="col-xl-3 d-flex justify-content center">
                    <a-input
                      type="number"
                      v-model="item.resultValue"
                      style="width: 25%"
                    ></a-input>
                    <div class="" style="width: 55%">
                      <p class="m-0 ml-3 mt-2">{{ item.unit }}</p>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <p>{{ indicator.referenceValue }}</p>
                  </div>
                  <div class="col-xl-2">
                    <p>{{ indicator.criticalLow }}</p>
                  </div>
                  <div class="col-xl-2">
                    <p>{{ indicator.criticalHigh }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mt-4">
            <a-button
              type="primary"
              class="mt-2 d-flex flex-row align-items-center justify-content-center"
              style="width: 230px; border-radius: 26px"
              @click="submitData"
            >
              <img
                src="resources/images/save.png"
                alt="Icon"
                style="height: 18px; width: 18px"
              />
              <span style="margin-left: 5px">Simpan Pemeriksaan</span></a-button
            >
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Data Spesimen" force-render>
          <div class="row mt-5">
            <div class="col-2">Petugas Pengambil Spesimen</div>
            <div class="col-10">{{ spesimenCreator }}</div>
          </div>
          <a-divider></a-divider>
          <div v-for="(item, idx) in masterSpesimen" :key="idx" class="my-4">
            <h6>{{ item.name }}</h6>
            <div class="row">
              <div class="col-2">Status Spesimen</div>
              <div class="col-10">
                {{ item.statusDisplay }}
              </div>
            </div>
            <a-divider></a-divider>
            <div class="row">
              <div class="col-2">Asal Sumber</div>
              <div class="col-10">{{ item.sourceOfOriginDisplay }}</div>
            </div>
            <div class="row mt-5">
              <div class="col-2">Lokasi Pengambilan</div>
              <div class="col-10">{{ item.locationDisplay }}</div>
            </div>
            <div class="row mt-5">
              <div class="col-2">Jumlah dan Volume</div>
              <div class="col-10">{{ item.value }} {{ item.unit }}</div>
            </div>
            <div class="row mt-5">
              <div class="col-2">Waktu Pengambilan</div>
              <div class="col-10">{{ item.time }}</div>
            </div>
            <div class="row mt-5">
              <div class="col-2">Metode Pengambilan</div>
              <div class="col-10">
                {{ item.methodDisplay }}
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-2">Kondisi Pada Saat Pengambilan</div>
              <div class="col-10">{{ item.conditionDisplay }}</div>
            </div>
            <div class="row mt-5">
              <div class="col-2">Fiksasi</div>
              <div class="col-10">Tidak</div>
            </div>
          </div>
          <a-divider></a-divider>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal
      v-model="modalVisible"
      title="LOINC Permintaan Pemeriksaan Lab Patologi Kinis"
      centered
      width="1400px"
      :footer="null"
    >
      <div>
        <p>Kode LOINC*</p>
        <a-input-search
          placeholder="Ketik nama pemeriksaan untuk mencari..."
          style="width: 350px"
          @change="searchKodeLoinc"
          class="mb-4"
        />
      </div>
      <a-table
        :dataSource="filteredLoincCode"
        :columns="loincCodeColumns"
        @rowClick="handleRowClick"
        :rowKey="(record) => record.No"
      />
    </a-modal>
    <a-modal
      v-model="modalDeleteVisible"
      :title="`Hapus ${deleteTitle}`"
      @ok="handleDelete"
      centered
    >
      <WarningOutlined />
      <span class="ml-2">Yakin Ingin Menghapus {{ deleteTitle }}</span>
    </a-modal>
  </div>
</template>

<script>
import {
  // getExaminationById,
  getExaminationsCategories,
  getLoincCodeAndAnswer,
  // createExamination,
  deleteExaminations,
  // updateExamination,
  getExaminationRequestById,
  createExaminationResult,
  automaticInputExamination,
} from '@/services/axios/api/pemeriksaan'
import { notification } from 'ant-design-vue'
import { WarningOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'

import { getPatientVisit } from '@/services/axios/api/kunjungan'

import { getUserProfileFromId } from '@/services/axios/api/auth'
export default {
  components: {
    // Daftarkan ikon sebagai komponen
    // UserOutlined,
    // HomeOutlined,
    WarningOutlined,
    InfoCircleOutlined,
  },
  data: function () {
    const columns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'no',
        width: '50px',
      },
      {
        title: 'Kategori Lab',
        dataIndex: 'ExaminationCategory.name',
        key: 'ExaminationCategory.name',
        width: '200px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'name',
        key: 'id',
      },
    ]
    const loincCodeColumns = [
      {
        title: 'Kode LOINC',
        dataIndex: 'Code',
        key: 'Code',
        width: '120px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'Nama Pemeriksaan',
        key: 'Nama Pemeriksaan',
        width: '300px',
      },
      {
        title: 'Permintaan/Hasil',
        dataIndex: 'Permintaan/Hasil',
        key: 'Permintaan/Hasil',
        width: '200px',
      },
      {
        title: 'Spesimen',
        dataIndex: 'Spesimen',
        key: 'Spesimen',
        width: '150px',
      },
      {
        title: 'Tipe Hasil',
        dataIndex: 'Tipe hasil pemeriksaan',
        key: 'Tipe Hasil Pemeriksaan',
        width: '110px',
      },
      {
        title: 'Satuan',
        dataIndex: 'Satuan',
        key: 'Satuan',
        width: '100px',
      },
      {
        title: 'Metode Analisis',
        dataIndex: 'Metode Analisis',
        key: 'Metode Analisis',
        width: '120px',
      },
    ]
    return {
      loadingAutomationInput: false,
      example: [],
      name: '',
      filterText: '', // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns,
      categories: [],
      selectedCategory: '',
      selectedGender: '',
      components: [
        {
          name: '',
          loincCode: '',
          resultType: '',
          unit: '',
          indicators: [],
        },
      ],
      masterComponents: [],
      categoryOptions: [
        {
          label: 'Apple',
          value: 'Apple',
        },
        {
          label: 'Pear',
          value: 'Pear',
        },
        {
          label: 'Orange',
          value: 'Orange',
        },
      ],
      genderOptions: [
        {
          label: 'L',
          value: 'L',
        },
        {
          label: 'P',
          value: 'P',
        },
      ],
      dateOptions: [
        {
          label: 'Month',
          value: 'M',
        },
        {
          label: 'Year',
          value: 'Y',
        },
      ],
      selectedCategoryLab: '',
      loincCode: [],
      filteredLoincCode: [],
      loincAnswer: [],
      filteredLoincAnswer: [],
      modalVisible: false,
      loincCodeColumns,
      masterExamination: {
        name: '',
        categoryId: '',
        loincCode: '',
      },
      loincType: '',
      selectedComponentId: '',
      loincAnswerList: [],
      answerList: [],
      modalDeleteVisible: false,
      deleteTitle: '',
      patientData: {},
      fileList: [],
      patientVisitData: [],
      masterSpesimen: [],
      spesimenCreator: '',
    }
  },
  created() {
    const { id } = this.$route.params
    this.getName()
    this.fetchData(id)
  },

  methods: {
    handleBeforeUpload(file) {
      if (this.fileList.length >= 1) {
        this.$message.warning('Hanya dapat mengunggah satu file.')
        return false // Tolak unggahan file tambahan
      }
      this.fileList = [file] // Ganti file yang ada dengan file baru
      return false // Hindari unggahan otomatis
    },
    async handleChange(info, item, idx) {
      this.loadingAutomationInput = true
      // console.log(item, idx, 'inidia')
      const templateComponents = item.map((e) => e.name)
      console.log(templateComponents)
      console.log(item)
      const file = info.fileList[0].originFileObj // Dapatkan file asli yang diunggah
      console.log(file, '<<<<<<')
      const formData = new FormData()
      formData.append('file', file) // Tambahkan file ke FormData
      // Opsional: tambahkan parameter tambahan jika diperlukan
      // formData.append("key", "value");

      try {
        const response = await automaticInputExamination(
          formData,
          templateComponents,
        ) // Kirim ke backend
        item = item.map((e) => {
          let resultValue = ''
          response.data.forEach((el) => {
            if (el.nama === e.name && !e.resultValue) {
              resultValue = el.nilaiHasil
            }
          })
          return {
            ...e,
            resultValue,
          }
        })
        console.log(response.data, 'inihasilnya coy')
        console.log(item, 'iniitemnya coy')
        this.masterComponents[idx].MasterExamination.ExaminationComponents =
          item
        if (response) {
          this.loadingAutomationInput = false
          this.$message.success('File berhasil diunggah dan diproses!')
        } else {
          this.$message.error('Gagal mengunggah file. Silakan coba lagi.')
        }
      } catch (error) {
        console.error('Error saat mengunggah file:', error)
        this.$message.error('Terjadi kesalahan saat mengunggah file.')
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const date = new Date(dateString)
      return date.toLocaleString('id-ID', options)
    },
    formatDateTime(dateString) {
      const date = new Date(dateString)
      const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }

      const formattedDate = date
        .toLocaleDateString('id-ID', dateOptions)
        .split('/')
        .join('-')
      const formattedTime = date
        .toLocaleTimeString('id-ID', timeOptions)
        .split('.')
        .join(':')

      return `${formattedDate} ${formattedTime}`
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error('Error fetching user name:', error)
        return 'Unknown'
      }
    },
    async getPatientVisitData(id) {
      const response = await getPatientVisit(id)
      this.patientVisitData = response.data.visit

      // console.log(this.patientVisitHistory.length, 'panjang data kunjungan history')
      // this.patientVisitHistory = this.patientVisitHistory.reverse()
      // console.log(this.patientVisitHistory, 'data kunjungan history')

      const perawatName = await this.getUserName(
        this.patientVisitData.Anamnesis.created_by,
      )

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAgeDefault(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === 'Male'
            ? 'Laki-laki'
            : 'Perempuan',
        payment_method:
          this.patientVisitData.Patient.payment_method === 'JKN'
            ? 'JKN (BPJS Kesehatan)'
            : this.patientVisitData.Patient.payment_method,
        anamnesis_created_time: this.formatDateTime(
          this.patientVisitData.Anamnesis.createdAt,
        ),
        anamnesis_created_by: perawatName,
      }

      console.log(this.patientVisitData, 'lucekini')

      // console.log(this.patientVisitData, 'data kunjungan pasien')
      // console.log(this.patientVisitDocument, 'dokumen kunjungan pasien')
    },

    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    calculateAge(birthDate, type) {
      console.log(birthDate, 'initgllahir')
      const birth = new Date(birthDate)
      const now = new Date()

      if (type === 'Y') {
        // Hitung umur dalam tahun
        let age = now.getFullYear() - birth.getFullYear()
        if (
          now.getMonth() < birth.getMonth() ||
          (now.getMonth() === birth.getMonth() &&
            now.getDate() < birth.getDate())
        ) {
          age-- // Kurangi jika belum melewati ulang tahun
        }
        return age
      } else if (type === 'M') {
        // Hitung umur dalam bulan
        const years = now.getFullYear() - birth.getFullYear()
        const months = now.getMonth() - birth.getMonth()
        const totalMonths = years * 12 + months

        if (now.getDate() < birth.getDate()) {
          return totalMonths - 1 // Kurangi jika hari bulan belum dilewati
        }
        return totalMonths
      }
      return 0 // Default jika tipe tidak valid
    },
    calculateAgeDefault(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },

    async fetchData(id) {
      const { data: dataRequest } = await getExaminationRequestById(
        this.$route.params.id,
      )
      // console.log(dataRequest.data.Visiz, 'lu cek ini')
      await this.getPatientVisitData(dataRequest.data.Visit.id)
      let isMalePatient = false
      if (dataRequest.data.Visit.Patient.gender === 'Male') {
        isMalePatient = true
      }
      const spesimen = dataRequest.data.ExaminationRequestDetails.map((e) => {
        this.spesimenCreator = e.ExaminationSpesimens[0].User.name
        return {
          ...e.ExaminationSpesimens[0],
          name: e.MasterExamination.name,
        }
      })
      console.log(spesimen, 'inispesimen')
      this.masterSpesimen = spesimen

      // console.log(dataRequest.data.Visit.Patient.birth_date, 'lu cek ini')
      this.masterComponents = dataRequest.data.ExaminationRequestDetails.map(
        (e) => {
          e.MasterExamination.ExaminationComponents.forEach((el) => {
            console.log(el.ExaminationIndicators, 'iniindikator')

            if (el.ExaminationIndicators.length > 1) {
              // Hitung umur pasien dalam tahun dan bulan
              const patientAgeYears = this.calculateAge(
                dataRequest.data.Visit.Patient.birth_date,
                'Y',
              )
              const patientAgeMonths = this.calculateAge(
                dataRequest.data.Visit.Patient.birth_date,
                'M',
              )

              console.log('Umur pasien dalam tahun:', patientAgeYears)
              console.log('Umur pasien dalam bulan:', patientAgeMonths)

              // Filter indikator berdasarkan umur dan gender
              el.ExaminationIndicators = el.ExaminationIndicators.filter(
                (element) => {
                  // Filter berdasarkan gender
                  const isGenderMatch = isMalePatient
                    ? element.isMale
                    : element.isFemale

                  // Bandingkan umur pasien dengan batas minimum
                  const minAgeComparison =
                    element.minAgeType === 'Y'
                      ? patientAgeYears >= (element.minAge || 0)
                      : patientAgeMonths >= (element.minAge || 0)

                  // Bandingkan umur pasien dengan batas maksimum
                  const maxAgeComparison =
                    element.maxAgeType === 'Y'
                      ? patientAgeYears <= (element.maxAge || Infinity)
                      : patientAgeMonths <= (element.maxAge || Infinity)

                  // Return true jika gender, minAge, dan maxAge sesuai
                  return isGenderMatch && minAgeComparison && maxAgeComparison
                },
              )
            }
          })

          return {
            ...e,
          }
        },
      )
      console.log(this.masterComponents, 'inimasterkomponent')
      this.patientData = dataRequest.data.Visit.Patient
      this.masterExamination = dataRequest.data.MasterExamination

      const { data: dataCategories } = await getExaminationsCategories()
      const { data: dataLoinc } = await getLoincCodeAndAnswer()
      this.loincCode = dataLoinc.data.LoincCode
      this.filteredLoincCode = dataLoinc.data.LoincCode
      this.loincAnswer = dataLoinc.data.LoincAnswer
      this.filteredLoincAnswer = dataLoinc.data.LoincAnswer

      this.categories = dataCategories.categories
      // console.log(dataCategories, 'inicategories')
      this.categoryOptions = dataCategories.categories.map((e) => {
        let disabled = false
        if (e.id === 2) {
          disabled = true
        }
        return {
          label: e.name,
          value: e.id,
          disabled,
        }
      })
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase() // Convert to lowercase for case-insensitive matching
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(e) {
      if (e) {
        this.filteredDataSource = this.dataSource.filter(
          (item) => item.ExaminationCategory.name === e,
        )
      }
    },
    addComponents() {
      const component = {
        name: '',
        loincCode: '',
        resultType: '',
        unit: '',
        indicators: [],
      }
      this.components.push(component)
    },
    addIndicator(index) {
      const indicator = {
        name: '',
        gender: '',
        minAge: '0',
        minAgeType: 'M/Y',
        maxAge: '0',
        maxAgeType: 'M/Y',
        referenceValue: '',
        criticalLow: '',
        criticalHigh: '',
      }
      console.log(this.components, 'iniabisadd')
      this.components[index].indicators.push(indicator)
    },
    handleModal(type, idx) {
      if (type === 'master') {
        this.loincType = 'master'
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'] !== 'Hasil',
        )
      } else if (type === 'component') {
        this.loincType = 'component'
        this.selectedComponentId = idx
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'] !== 'Permintaan',
        )
      }
      this.modalVisible = !this.modalVisible
    },
    async submitData() {
      // console.log(this.masterComponents)
      const examinationResult = []
      this.masterComponents.map((e) => {
        return {
          idExaminationRequestDetail: e.id,
          idExamintaionComponents: e.MasterExamination,
        }
      })
      for (let i = 0; i < this.masterComponents.length; i++) {
        const idExaminationRequestDetail = this.masterComponents[i].id
        for (
          let j = 0;
          j <
          this.masterComponents[i].MasterExamination.ExaminationComponents
            .length;
          j++
        ) {
          const obj = {
            idExaminationRequestDetail,
            idExaminationComponent:
              this.masterComponents[i].MasterExamination.ExaminationComponents[
                j
              ].id,
            resultValue:
              this.masterComponents[i].MasterExamination.ExaminationComponents[
                j
              ].resultValue,
          }
          examinationResult.push(obj)
        }
      }
      console.log(examinationResult, 'this is the final')
      const { id } = this.$route.params

      const { data } = await createExaminationResult(id, examinationResult)
      console.log(data)
      if (data.type === 'success') {
        notification.success({
          message: data.message,
          description: '',
          duration: 5,
        })
        this.$router.push('/laborant/klinik')
      }
    },
    searchKodeLoinc(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredLoincCode = this.loincCode.filter((item) =>
        item['Nama Pemeriksaan'].toLowerCase().includes(filterValue),
      )
    },
    handleRowClick(record) {
      console.log(record)
      if (this.loincType === 'master') {
        this.masterExamination.name = record['Nama Pemeriksaan']
        this.masterExamination.loincCode = record.Code
      } else if (this.loincType === 'component') {
        // if (
        //   !this.components[this.selectedComponentId].indicators[0].id ||
        //   !this.components[this.selectedComponentId].answerList[0].id
        // ) {
        //   this.components[this.selectedComponentId].indicators = []
        //   this.components[this.selectedComponentId].answerList = []
        // }
        if (
          record['Tipe hasil pemeriksaan'] === 'Quantitative' &&
          this.components[this.selectedComponentId].answerList[0].id
        ) {
          this.components[this.selectedComponentId].answerList = []
        }
        this.components[this.selectedComponentId].loincAnswerList = []
        this.components[this.selectedComponentId].name =
          record['Nama Pemeriksaan']
        this.components[this.selectedComponentId].resultType =
          record['Tipe hasil pemeriksaan']
        this.components[this.selectedComponentId].loincCode = record.Code
        if (record.Satuan) {
          this.components[this.selectedComponentId].unit = record.Satuan
        }
        if (
          record['Tipe hasil pemeriksaan'] === 'Quantitative' ||
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          if (!this.components[this.selectedComponentId].indicators.length) {
            this.addIndicator(this.selectedComponentId)
          }
        }
        if (
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          let filteredData = this.loincAnswer.filter(
            (e) =>
              e['LOINC Code'] ===
              this.components[this.selectedComponentId].loincCode,
          )
          console.log(filteredData, 'inifilteranswer')
          filteredData = filteredData.map((e, idx) => {
            return {
              ...this.components[this.selectedComponentId].answerList[idx],
              ...e,
              answerList: e['Answer List '],
              answerCode: e.Code,
            }
          })
          this.components[this.selectedComponentId].loincAnswerList =
            filteredData
          this.components[this.selectedComponentId].answerList = filteredData
        }
      }
      this.modalVisible = !this.modalVisible
      // this.$router.push('/')
    },
    async handleData(dataType, actionType, index, indexIndicator, record) {
      if (dataType === 'component') {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.components.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index + 1])
            this.$set(this.components, index + 1, temp)
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index - 1])
            this.$set(this.components, index - 1, temp)
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          if (record.id) {
            this.handleModalDelete('Component')
            this.typeDeleteData = 'component'
            this.deleteDataId = record.id
          } else {
            this.components.splice(index, 1)
          }
        }
      } else {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (indexIndicator < this.components[index].indicators.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator + 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator + 1,
              temp,
            )
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (indexIndicator > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator - 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator - 1,
              temp,
            )
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          if (record[0].id) {
            this.handleModalDelete('Indicator')
            this.typeDeleteData = 'indicator'
            this.deleteDataId = record[0].id
            const { id } = this.$route.params
            this.fetchData(id)
          } else {
            this.components[index].indicators.splice(indexIndicator, 1)
          }
        }
      }
    },
    handleModalDelete(title) {
      this.deleteTitle = title
      this.modalDeleteVisible = !this.modalDeleteVisible
    },
    async handleDelete() {
      await deleteExaminations(this.typeDeleteData, this.deleteDataId)
      const { id } = this.$route.params
      this.fetchData(id)
      this.modalDeleteVisible = false
      this.deleteDataId = ''
      this.typeDeleteData = ''
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>

<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="card-body">
          <div class="row" style="background-color: #f3fdff">
            <div class="col-3 container">
              <img src="resources/images/medisync-big.svg" alt="Medisync" height="50px" />
            </div>
            <div class="col-6 header-text container">
              Master Pemeriksaan Laboratorium
            </div>
            <div class="col-3 container">
              {{ name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row px-4 mb-4">
        <div class="col-2 p-0 pr-3">
          <a-button type="primary" class="w-100" style="height: 31.5px" @click="handleAddExamination">
            <div class="d-flex align-items-center justify-content-center">
              <img src="resources/images/plus-icon2.svg" alt="Icon" style="height: 16px; width: 16px" />
              <span class="ml-1">Tambah Pemeriksaan</span>
            </div>
          </a-button>
        </div>
        <div class="col-4 p-0 pr-3">
          <a-select ref="select" style="width: 100%" v-model="selectedCategory" @change="handleChangeCategories">
            <a-select-option value="" key="">
              <div class="d-flex align-items-center">
                <img src="resources/images/filter-icon.svg" alt="Icon" style="height: 16px; width: 16px" />
                <span class="ml-2">Pilih Kategori Lab</span>
              </div>
            </a-select-option>
            <a-select-option v-for="(category, idx) in categories" :value="category.name" :key="idx">
              {{ category.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-6 p-0">
          <a-input placeholder="Filter by Nama Pemeriksaan" @input="filterExamination" style="height: 31.5px" />
        </div>
      </div>

      <a-table :dataSource="filteredDataSource" :columns="columns" rowKey="id">
        <template slot="actions" slot-scope="record">
          <div class="d-flex justify-content-end">
            <button class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
              style="width: 100px; border-radius: 26px" @click.prevent="goToDetail(record)">
              <img src="/resources/images/edit.svg" alt="edit" class="icon-default" />
              <img src="/resources/images/edit-hover.svg" alt="edit-hover" class="icon-hover" />
              <span class="ml-2">Ubah</span>
            </button>
            <button @click.prevent="handleToggleDelete(record.id, record.name)"
              class="d-flex align-items-center justify-content-center btn btn-outline-danger delete-button"
              style="width: 100px; border-radius: 26px">
              <img src="/resources/images/delete.svg" alt="delete" class="icon-default" />
              <img src="/resources/images/delete-hover.svg" alt="delete-hover" class="icon-hover" />
              <span class="ml-2">Hapus</span>
            </button>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model="modalDeleteVisible" title="Hapus Pemeriksaan Lab?" @ok="handleDelete" centered :footer="null">
      <p>Apakah Anda yakin ingin menghapus pemeriksaan <strong>{{ selectedDeleteName }}</strong>? Aksi
        ini
        akan <strong>menghapus</strong>
        pemeriksaan
        tersebut <strong>secara permanen</strong> .</p>
      <div class="d-flex justify-content-end mt-4">
        <button class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
          style="width: 50%; border-radius: 26px" @click.prevent="handleCloseModal">

          <span class="ml-2">Kembali</span>
        </button>
        <button @click.prevent="handleDelete"
          class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
          style="width: 50%; border-radius: 26px">
          <span class="ml-2">Ya</span>
        </button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getExaminations,
  getExaminationsCategories,
  deleteExaminations,
} from '@/services/axios/api/pemeriksaan'
import { } from '@ant-design/icons-vue'

export default {
  data() {
    return {
      name: '',
      filterText: '', // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns: [
        {
          title: 'No',
          dataIndex: 'key',
          key: 'no',
          width: '50px',
        },
        {
          title: 'Kategori Lab',
          dataIndex: 'ExaminationCategory.name',
          key: 'ExaminationCategory.name',
          width: '200px',
        },
        {
          title: 'Nama Pemeriksaan',
          dataIndex: 'name',
          key: 'id',
        },
        {
          title: 'Aksi',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: '200px',
        },
      ],
      categories: [],
      selectedCategory: '',
      modalDeleteVisible: false,
      selectedDeleteId: '',
      selectedDeleteName: '',
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },
  methods: {
    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    async fetchData() {
      try {
        const { data } = await getExaminations()
        const { data: dataCategories } = await getExaminationsCategories()
        this.categories = dataCategories.categories

        this.dataSource = data.pemeriksaan.map((e, idx) => ({
          ...e,
          key: idx + 1,
        }))

        this.filteredDataSource = [...this.dataSource]
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(selectedCategory) {
      this.filteredDataSource = selectedCategory
        ? this.dataSource.filter(
          (item) => item.ExaminationCategory.name === selectedCategory,
        )
        : [...this.dataSource]
    },
    handleAddExamination() {
      this.$router.push('/laborant/add')
    },
    goToDetail(record) {
      console.log(record, '<<<')
      this.$router.push(`/laborant/detail/${record.id}`)
    },
    async handleToggleDelete(id, name) {
      console.log(id)
      this.selectedDeleteId = id
      this.selectedDeleteName = name
      this.modalDeleteVisible = !this.modalDeleteVisible
    },
    handleCloseModal() {
      this.modalDeleteVisible = false
    },
    async handleDelete() {
      try {
        await deleteExaminations('master', this.selectedDeleteId)
        this.fetchData() // Refresh data setelah penghapusan
        this.$notification.success({
          message: 'Berhasil',
          description: 'Data Master Pemeriksaan berhasil dihapus',
          duration: 5,
        })
        this.modalDeleteVisible = false
      } catch (error) {
        console.error('Error deleting data:', error)
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.delete-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.delete-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.delete-button:hover .icon-default {
  display: none;
}

.delete-button:hover .icon-hover {
  display: inline;
}

/* Default icon terlihat */
.edit-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.edit-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.edit-button:hover .icon-default {
  display: none;
}

.edit-button:hover .icon-hover {
  display: inline;
}
</style>

<template>
  <div class="row">
    <div class="col-12">
      <h5 style="color: #428a94; font-weight: bold">
        Tambah Pemeriksaan Laboratorium
      </h5>
      <h6 style="font-weight: 500" class="mt-5">Pemeriksaan</h6>
      <div class="row">
        <div class="col-4 col-sm-12 my-4">
          <p>Kategori Laboratorium<span style="color: #dc3545">*</span></p>
          <a-radio-group
            :options="categoryOptions"
            v-model="masterExamination.categoryId"
          />
        </div>
        <div class="col-12 mt-4 mb-3">
          <p>Kode LOINC<span style="color: #dc3545">*</span></p>
          <div class="row px-3">
            <a-button
              type="secondary"
              class="d-flex align-items-center justify-content-between col-12 col-sm-6 col-md-4"
              @click="handleModal('master')"
            >
              <span>{{
                masterExamination.loincCode
                  ? masterExamination.loincCode
                  : 'Cari LOINC'
              }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
          </div>
        </div>
        <div class="col-12 mt-4">
          <p>Nama Pemeriksaan<span style="color: #dc3545">*</span></p>
          <div class="row px-3">
            <a-input
              placeholder="Nama Pemeriksaan"
              class="col-12 col-sm-6 col-md-4"
              v-model="masterExamination.name"
            ></a-input>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center mt-3"
        v-if="!masterExamination.isCategoryExist"
      >
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Kategori pemeriksaan masih kosong.
        </p>
      </div>
      <div
        class="d-flex align-items-center mt-3"
        v-if="!masterExamination.isLoincCodeExist"
      >
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Kode LOINC pemeriksaan masih kosong.
        </p>
      </div>
      <div
        class="d-flex align-items-center mt-3"
        v-if="!masterExamination.isNameExist"
      >
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Nama pemeriksaan masih kosong.
        </p>
      </div>
      <div
        class="d-flex align-items-center mt-3"
        v-if="!masterExamination.isNameTaken"
      >
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Sudah ada pemeriksaan dengan nama ini.
        </p>
      </div>

      <a-divider></a-divider>
      <h6>Komponen Pemeriksaan</h6>
      <p>
        Anda perlu menambahkan setidaknya satu komponen pemeriksaan ke dalam
        pemeriksaan ini. Komponen pemeriksaan dengan tipe hasil kuantitatif,
        nominal, dan ordinal dapat memiliki satu atau lebih indikator. Selain
        itu, komponen pemeriksaan dengan tipe hasil nominal dan ordinal memiliki
        nilai hasil berdasarkan standar LOINC. Indikator harus mencakup semua
        jenis kelamin dan rentang umur 0 bulan hingga 120 tahun.
      </p>
      <div v-for="(item, idx) in components" :key="idx" class="card p-3">
        <div class="row">
          <!-- INI COMPONENT ROW -->
          <div class="col-12 my-2 my-xl-0 col-xl-3">
            <p>Kode LOINC<span style="color: #dc3545">*</span></p>
            <a-button
              type="secondary"
              class="w-100 d-flex align-items-center justify-content-between"
              @click="handleModal('component', idx)"
            >
              <span>{{ item.loincCode ? item.loincCode : 'Cari LOINC' }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
          </div>
          <div class="col-12 my-2 my-xl-0 col-xl-3">
            <p>
              Nama Komponen Pemeriksaan<span style="color: #dc3545">*</span>
            </p>
            <a-input
              placeholder="Nama Komponen Pemeriksaan"
              v-model="item.name"
            ></a-input>
          </div>

          <div class="col-12 my-2 my-xl-0 col-xl-2">
            <p>Tipe Hasil<span style="color: #dc3545">*</span></p>

            <a-input
              placeholder="Tipe Hasil"
              v-model="item.resultType"
              disabled
            ></a-input>
          </div>
          <div class="col-12 my-2 my-xl-0 col-xl-2">
            <p>Satuan Ukuran</p>
            <a-input
              placeholder="Satuan Ukuran"
              v-model="item.unit"
              disabled
            ></a-input>
          </div>
          <div
            class="col-12 mt-4 mb-2 d-flex align-items-center justify-content-center d-flex d-xl-none"
          >
            <div class="row">
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-down.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="components.length > 1 && idx !== components.length - 1"
                  @click="handleData('component', 'down', idx)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-up.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="components.length > 1 && idx"
                  @click="handleData('component', 'up', idx)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/x.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  @click="handleData('component', 'delete', idx)"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- INI RULES KOMPONEN -->
        <div
          class="d-flex align-items-center mt-3"
          v-if="!item.isLoincCodeExist"
        >
          <InfoCircleOutlined style="color: #dc3545" />
          <p class="m-0 ml-2" style="color: #dc3545">
            Kode LOINC Komponen masih kosong.
          </p>
        </div>
        <div class="d-flex align-items-center mt-3" v-if="!item.isNameExist">
          <InfoCircleOutlined style="color: #dc3545" />
          <p class="m-0 ml-2" style="color: #dc3545">
            Nama Komponen masih kosong.
          </p>
        </div>
        <div
          v-if="item.resultType === 'Ordinal' || item.resultType === 'Nominal'"
        >
          <div class="row mt-2">
            <div class="col-4">
              <p>Nilai Hasil dari LOINC</p>
              <a-input
                v-for="(answerTemplate, idx) in item.loincAnswerList"
                :key="idx"
                v-model="answerTemplate['Answer List ']"
                disabled
                class="my-2"
                >{{ answerTemplate.Code }}</a-input
              >
            </div>

            <div class="col-4">
              <p>Tampilan Nilai Hasil</p>
              <a-input
                v-for="(answer, idx) in item.answerList"
                :key="idx"
                v-model="answer['Answer List ']"
                class="my-2"
              ></a-input>
            </div>
            <!-- INI RULES ANSWER LIST -->
            <div
              class="d-flex align-items-center mt-3 col-12"
              v-if="item.isEmptyAnswer"
            >
              <InfoCircleOutlined style="color: #dc3545" />
              <p class="m-0 ml-2" style="color: #dc3545">
                Ada Nilai Hasil yang kosong.
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="
            item.resultType === 'Quantitative' ||
            item.resultType === 'Ordinal' ||
            item.resultType === 'Nominal'
          "
        >
          <div
            class="row mt-4"
            style="position: relative"
            v-for="(indicator, indexIndicator) in item.indicators"
            :key="indexIndicator"
          >
            <!-- INI INDICATOR ROW -->
            <div class="col-12 my-2 col-xl-2">
              <p v-if="!indexIndicator">
                Nama Indikator<span style="color: #dc3545">*</span>
              </p>
              <a-input
                placeholder="Cth: Laki-laki Dewasa"
                v-model="indicator.name"
              ></a-input>
            </div>
            <div class="col-12 my-2 col-xl-2 px-4 ml-xl-2">
              <p v-if="!indexIndicator">
                Jenis Kelamin<span style="color: #dc3545"
                  ><span style="color: #dc3545">*</span></span
                >
              </p>
              <div style="margin-top: 5px">
                <a-checkbox v-model="indicator.isMale">Laki Laki</a-checkbox>
                <a-checkbox v-model="indicator.isFemale">Perempuan</a-checkbox>
              </div>
            </div>
            <div class="col-12 my-2 col-xl-3">
              <p v-if="!indexIndicator">
                Rentang Umur<span style="color: #dc3545">*</span>
              </p>
              <div class="row">
                <div class="col-12 col-xl-5 pr-0">
                  <div class="row">
                    <div class="col-3 col-xl-4 pr-xl-0">
                      <a-input
                        type="number"
                        placeholder=""
                        class="pr-xl-1"
                        v-model="indicator.minAge"
                      ></a-input>
                    </div>
                    <div class="col-9 col-xl-8">
                      <a-select
                        v-model="indicator.minAgeType"
                        class="w-100"
                        :options="dateOptions"
                      ></a-select>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 my-2 col-xl-2 p-0 d-flex align-items-center justify-content-center"
                >
                  s/d
                </div>
                <div class="col-12 col-xl-5 pr-0">
                  <div class="row">
                    <div class="col-3 col-xl-4 pr-xl-0">
                      <a-input
                        type="number"
                        placeholder=""
                        class="pr-xl-1"
                        v-model="indicator.maxAge"
                        style="width: 55px"
                      ></a-input>
                    </div>
                    <div class="col-9 col-xl-8">
                      <a-select
                        v-model="indicator.maxAgeType"
                        class="w-100"
                        :options="dateOptions"
                      ></a-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 my-2 col-xl-4">
              <div class="row">
                <div class="col-xl-3">
                  <p v-if="!indexIndicator">
                    Nilai Rujukan<span style="color: #dc3545">*</span>
                  </p>
                  <a-input
                    placeholder="Cth: 1-10"
                    v-model="indicator.referenceValue"
                  ></a-input>
                </div>
                <div class="col-xl-4">
                  <p v-if="!indexIndicator">Nilai Kritis Rendah</p>
                  <a-input
                    placeholder="Cth: <1"
                    v-model="indicator.criticalLow"
                  ></a-input>
                </div>
                <div class="col-xl-4">
                  <p v-if="!indexIndicator">Nilai Kritis Tinggi</p>
                  <a-input
                    placeholder="Cth: >1"
                    v-model="indicator.criticalHigh"
                  ></a-input>
                </div>
              </div>
            </div>
            <!-- INI RULES INDIKATOR -->
            <div class="col-12 mb-3">
              <div
                class="d-flex align-items-center mt-3"
                v-if="indicator.isNameEmpty"
              >
                <InfoCircleOutlined style="color: #dc3545" />
                <p class="m-0 ml-2" style="color: #dc3545">
                  Nama Indikator masih kosong.
                </p>
              </div>
              <div
                class="d-flex align-items-center mt-3"
                v-if="indicator.isGenderEmpty"
              >
                <InfoCircleOutlined style="color: #dc3545" />
                <p class="m-0 ml-2" style="color: #dc3545">
                  Jenis Kelamin masih kosong.
                </p>
              </div>
              <div
                class="d-flex align-items-center mt-3"
                v-if="indicator.isReferenceValueEmpty"
              >
                <InfoCircleOutlined style="color: #dc3545" />
                <p class="m-0 ml-2" style="color: #dc3545">
                  Nilai Rujukan masih kosong.
                </p>
              </div>
            </div>
            <!-- ARROW2an -->
            <div
              style="
                position: absolute;
                right: 30px;
                bottom: 12px;
                width: 140px;
                height: 30px;
              "
              class="row"
            >
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-down.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="
                    item.indicators.length > 1 &&
                    indexIndicator !== item.indicators.length - 1
                  "
                  @click="handleData('indicator', 'down', idx, indexIndicator)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/arrow-up.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  v-if="item.indicators.length > 1 && indexIndicator"
                  @click="handleData('indicator', 'up', idx, indexIndicator)"
                />
              </div>
              <div
                class="col-4 d-flex align-items-center justify-content-center"
              >
                <img
                  src="resources/images/x.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  @click="
                    handleData('indicator', 'delete', idx, indexIndicator)
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex align-items-center"
          v-if="!item.isGenderValid && !item.isAgeValid"
        >
          <InfoCircleOutlined style="color: #dc3545" />
          <p class="m-0 ml-2" style="color: #dc3545">
            Indikator pemeriksaan belum mencakup jenis kelamin laki-laki dan
            perempuan serta rentang umur 0 bulan - 120 tahun.
          </p>
        </div>
        <div
          class="d-flex align-items-center mt-3"
          v-else-if="!item.isGenderValid && item.isAgeValid"
        >
          <InfoCircleOutlined style="color: #dc3545" />
          <p class="m-0 ml-2" style="color: #dc3545">
            Indikator pemeriksaan belum mencakup jenis kelamin laki-laki dan
            perempuan
          </p>
        </div>
        <div
          class="d-flex align-items-center mt-3"
          v-else-if="item.isGenderValid && !item.isAgeValid"
        >
          <InfoCircleOutlined style="color: #dc3545" />
          <p class="m-0 ml-2" style="color: #dc3545">
            Indikator pemeriksaan belum mencakup rentang umur 0 bulan - 120
            tahun.
          </p>
        </div>
        <div
          class="my-3 d-flex align-items-center"
          @click="addIndicator(idx)"
          v-if="item.resultType && item.resultType !== 'Narrative'"
        >
          <img
            src="resources/images/plus-icon.svg"
            alt="Icon"
            style="height: 16px; width: 16px; cursor: pointer"
          />
          <span style="color: #428a94; margin-left: 5px; cursor: pointer"
            >Tambah Indikator</span
          >
        </div>
        <!-- ARROW2an -->
        <div
          style="
            position: absolute;
            top: 52px;
            right: 30px;
            bottom: 17px;
            width: 140px;
            height: 30px;
          "
          class="row d-none d-xl-flex"
        >
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/arrow-down.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              v-if="components.length > 1 && idx !== components.length - 1"
              @click="handleData('component', 'down', idx)"
            />
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/arrow-up.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              v-if="components.length > 1 && idx"
              @click="handleData('component', 'up', idx)"
            />
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/x.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              @click="handleData('component', 'delete', idx)"
            />
          </div>
        </div>
        <div
          style="
            position: absolute;
            top: 52px;
            right: 30px;
            bottom: 17px;
            width: 140px;
            height: 30px;
          "
          class="row d-none d-xl-flex"
        >
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/arrow-down.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              v-if="components.length > 1 && idx !== components.length - 1"
              @click="handleData('component', 'down', idx)"
            />
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/arrow-up.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              v-if="components.length > 1 && idx"
              @click="handleData('component', 'up', idx)"
            />
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="resources/images/x.svg"
              alt="Icon"
              style="height: 24px; width: 24px; cursor: pointer"
              @click="handleData('component', 'delete', idx, '', item)"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div
          type="primary"
          class="d-flex align-items-center mb-4"
          style="width: 230px"
          @click="addComponents"
        >
          <img
            src="resources/images/plus-icon.svg"
            alt="Icon"
            style="height: 16px; width: 16px; cursor: pointer"
          />
          <span style="color: #428a94; margin-left: 5px; cursor: pointer"
            >Tambah Komponen Pemeriksaan</span
          >
        </div>
        <a-button
          type="primary"
          class="mt-2 d-flex flex-row align-items-center justify-content-center"
          style="width: 230px"
          @click="submitData"
        >
          <img
            src="resources/images/save.png"
            alt="Icon"
            style="height: 18px; width: 18px"
          />
          <span style="margin-left: 5px">Simpan Pemeriksaan</span></a-button
        >
      </div>
    </div>
    <a-modal
      v-model="modalVisible"
      title="LOINC Permintaan Pemeriksaan Lab Patologi Kinis"
      width="90%"
      :footer="null"
    >
      <div>
        <p>Kode LOINC<span style="color: #dc3545">*</span></p>
        <a-input-search
          placeholder="Ketik nama pemeriksaan untuk mencari..."
          style="width: 350px"
          @change="searchKodeLoinc"
          class="mb-4"
        />
      </div>
      <a-table
        :dataSource="filteredLoincCode"
        :columns="loincCodeColumns"
        @rowClick="handleRowClick"
        :rowKey="(record) => record.No"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  getExaminations,
  getExaminationsCategories,
  getLoincCodeAndAnswer,
  createExamination,
} from '@/services/axios/api/pemeriksaan'
import { notification } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    InfoCircleOutlined,
  },
  data: function () {
    const columns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'no',
        width: '50px',
      },
      {
        title: 'Kategori Lab',
        dataIndex: 'ExaminationCategory.name',
        key: 'ExaminationCategory.name',
        width: '200px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'name',
        key: 'id',
      },
    ]
    const loincCodeColumns = [
      {
        title: 'Kode LOINC',
        dataIndex: 'Code',
        key: 'Code',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'Nama Pemeriksaan',
        key: 'Nama Pemeriksaan',
      },
      {
        title: 'Permintaan/Hasil',
        dataIndex: 'Permintaan/Hasil',
        key: 'Permintaan/Hasil',
      },
      {
        title: 'Spesimen',
        dataIndex: 'Spesimen',
        key: 'Spesimen',
      },
      {
        title: 'Tipe Hasil',
        dataIndex: 'Tipe hasil pemeriksaan',
        key: 'Tipe Hasil Pemeriksaan',
      },
      {
        title: 'Satuan',
        dataIndex: 'Satuan',
        key: 'Satuan',
      },
      {
        title: 'Metode Analisis',
        dataIndex: 'Metode Analisis',
        key: 'Metode Analisis',
      },
    ]
    return {
      example: [],
      name: '',
      filterText: '', // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns,
      categories: [],
      selectedCategory: '',
      selectedGender: '',
      components: [
        {
          name: '',
          loincCode: '',
          resultType: '',
          unit: '',
          indicators: [],
          isGenderValid: true,
          isAgeValid: true,
          isNameExist: true,
          isLoincCodeExist: true,
        },
      ],
      categoryOptions: [],
      genderOptions: [
        {
          label: 'L',
          value: 'L',
        },
        {
          label: 'P',
          value: 'P',
        },
      ],
      dateOptions: [
        {
          label: 'Month',
          value: 'M',
        },
        {
          label: 'Year',
          value: 'Y',
        },
      ],
      selectedCategoryLab: '',
      loincCode: [],
      filteredLoincCode: [],
      loincAnswer: [],
      filteredLoincAnswer: [],
      modalVisible: false,
      loincCodeColumns,
      masterExamination: {
        name: '',
        categoryId: '',
        loincCode: '',
        isNameExist: true,
        isNameTaken: true,
        isCategoryExist: true,
        isLoincCodeExist: true,
      },
      loincType: '',
      selectedComponentId: '',
      loincAnswerList: [],
      answerList: [],
      selectedTypeSearch: '',
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },

  methods: {
    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    async fetchData() {
      const { data } = await getExaminations()
      const { data: dataCategories } = await getExaminationsCategories()
      const { data: dataLoinc } = await getLoincCodeAndAnswer()
      // const tesFilter = dataLoinc.data.LoincCode.filter(
      //   (e) => e['Nama Pemeriksaan']?.includes('Glukosa darah sewaktu'), // Menggunakan includes
      // )
      const tesFilter = dataLoinc.data.LoincCode.filter(
        (e) => e.No === 1363 || e.No === 1369 || e.No === 1370,
      )
      console.log(tesFilter, 'ini?')
      // console.log(dataLoinc.data.LoincCode, 'ini?')
      this.loincCode = dataLoinc.data.LoincCode
      this.filteredLoincCode = dataLoinc.data.LoincCode
      this.loincAnswer = dataLoinc.data.LoincAnswer
      this.filteredLoincAnswer = dataLoinc.data.LoincAnswer

      // console.log(this.loincCode)
      // console.log(dataLoinc.data.LoincCode, 'iniloinc')
      this.categories = dataCategories.categories
      // console.log(dataCategories, 'inicategories')
      this.categoryOptions = dataCategories.categories.map((e) => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      const dataTable = data.pemeriksaan.map((e, idx) => {
        return {
          ...e,
          key: idx + 1,
        }
      })
      console.log(dataTable, 'inidatakeseluruhan')
      this.dataSource = dataTable
      this.filteredDataSource = dataTable
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase() // Convert to lowercase for case-insensitive matching
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(e) {
      if (e) {
        this.filteredDataSource = this.dataSource.filter(
          (item) => item.ExaminationCategory.name === e,
        )
      }
    },
    addComponents() {
      const component = {
        name: '',
        loincCode: '',
        resultType: '',
        unit: '',
        indicators: [],
        isGenderValid: true,
        isAgeValid: true,
        isNameExist: true,
        isLoincCodeExist: true,
      }
      this.components.push(component)
    },
    addIndicator(index) {
      const indicator = {
        name: '',
        isMale: false,
        isFemale: false,
        minAge: '0',
        minAgeType: 'M',
        maxAge: '120',
        maxAgeType: 'Y',
        referenceValue: '',
        criticalLow: '',
        criticalHigh: '',
      }
      this.components[index].indicators.push(indicator)
    },
    handleModal(type, idx) {
      if (type === 'master') {
        this.selectedTypeSearch = 'master'
        this.loincType = 'master'
        console.log(this.loincCode)
        const testFilter = this.loincCode.filter((e) => e.Code === '53397-6')
        console.log(testFilter, 'inites')
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'].trim() !== 'Hasil',
        )
      } else if (type === 'component') {
        this.selectedTypeSearch = 'component'
        this.loincType = 'component'
        this.selectedComponentId = idx
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'].trim() !== 'Permintaan',
        )
      }
      this.modalVisible = !this.modalVisible
    },
    async submitData() {
      let isQualified = true

      this.masterExamination.isNameExist = true
      this.masterExamination.isNameTaken = true
      this.masterExamination.isCategoryExist = true
      this.masterExamination.isLoincCodeExist = true

      // CEK MASTER
      if (!this.masterExamination.categoryId) {
        this.masterExamination.isCategoryExist = false
        isQualified = false
      }
      if (!this.masterExamination.name) {
        this.masterExamination.isNameExist = false
        isQualified = false
      } else {
        const examinationName = this.dataSource.filter(
          (e) => e.name === this.masterExamination.name,
        )
        console.log(examinationName, 'iniexaminationname')
        if (examinationName.length) {
          this.masterExamination.isNameTaken = false
          isQualified = false
        }
      }

      if (!this.masterExamination.loincCode) {
        this.masterExamination.isLoincCodeExist = false
        isQualified = false
      }
      // console.log(this.masterExamination, 'inisubmit')
      // console.log(this.components, 'inisubmit')
      for (let i = 0; i < this.components.length; i++) {
        this.components[i].isNameExist = true
        this.components[i].isLoincCodeExist = true
        this.components[i].isEmptyAnswer = false

        if (!this.components[i].name) {
          this.components[i].isNameExist = false
          isQualified = false
        }

        if (!this.components[i].loincCode) {
          this.components[i].isLoincCodeExist = false
          isQualified = false
        }

        if (this.components[i].resultType !== 'Narrative') {
          for (let x = 0; x < this.components[i].answerList.length; x++) {
            if (!this.components[i].answerList[x]['Answer List ']) {
              this.components[i].isEmptyAnswer = true
            }
          }
          let isMaleExist = false
          let isFemaleExist = false
          let isAgeQualified = true

          // Array to store all age ranges in months
          const ageRanges = []

          for (let j = 0; j < this.components[i].indicators.length; j++) {
            const indicator = this.components[i].indicators[j]
            indicator.isNameEmpty = false
            indicator.isGenderEmpty = false
            indicator.isReferenceValueEmpty = false
            // Check gender coverage
            if (!indicator.name) {
              indicator.isNameEmpty = true
              isQualified = false
            }
            if (!indicator.isMale && !indicator.isFemale) {
              indicator.isGenderEmpty = true
              isQualified = false
            }
            if (!indicator.referenceValue) {
              indicator.isReferenceValueEmpty = true
              isQualified = false
            }
            if (indicator.isMale) {
              isMaleExist = true
            }
            if (indicator.isFemale) {
              isFemaleExist = true
            }

            // Convert minAge and maxAge to months for comparison
            const minAgeInMonths =
              indicator.minAgeType === 'Y'
                ? indicator.minAge * 12
                : indicator.minAge
            const maxAgeInMonths =
              indicator.maxAgeType === 'Y'
                ? indicator.maxAge * 12
                : indicator.maxAge
            console.log(indicator.minAgeType, 'inimin')
            console.log(indicator.maxAgeType, 'inimax')
            // Add age range to the array
            ageRanges.push({ min: minAgeInMonths, max: maxAgeInMonths })
            console.log(indicator.minAge, indicator.minAgeType)
            console.log(indicator.maxAge, indicator.maxAgeType)
          }

          // Merge age ranges to check if they cover 0 to 1440 months (0 to 120 years)
          ageRanges.sort((a, b) => a.min - b.min) // Sort by min age
          console.log(ageRanges, 'cekini')
          let currentMax = 0

          for (let k = 0; k < ageRanges.length; k++) {
            if (ageRanges[k].min > currentMax) {
              // Gap found
              isAgeQualified = false
              break
            }
            currentMax = Math.max(currentMax, ageRanges[k].max)
          }
          console.log(isAgeQualified, 'atas')
          console.log(currentMax, 'inimax')

          if (currentMax < 1440) {
            isAgeQualified = false
          }
          console.log(isAgeQualified, 'bawah')
          // Update flags for each component
          if (!isMaleExist || !isFemaleExist) {
            isQualified = false
            this.components[i].isGenderValid = false
          } else {
            this.components[i].isGenderValid = true
          }

          if (!isAgeQualified) {
            isQualified = false
            this.components[i].isAgeValid = false
          } else {
            this.components[i].isAgeValid = true
          }
        }
      }
      this.components = this.components.map((e) => {
        return {
          ...e,
        }
      })
      if (isQualified) {
        const { data } = await createExamination(
          this.masterExamination,
          this.components,
        )
        console.log(data)
        if (data.type === 'success') {
          notification.success({
            message: data.message,
            description: '',
            duration: 5,
          })
          this.$router.push('/laborant')
        }
      } else {
        notification.warning({
          message: 'Gagal Membuat Pemeriksaan',
          description:
            'Inputan masih belum memenuhi requirement yang diperlukan',
          duration: 5,
        })
      }
    },
    searchKodeLoinc(e) {
      const filterValue = e.target.value.toLowerCase()
      if (this.selectedTypeSearch === 'master') {
        this.filteredLoincCode = this.loincCode.filter(
          (item) =>
            item['Nama Pemeriksaan'].toLowerCase().includes(filterValue) &&
            item['Permintaan/Hasil'].trim() !== 'Hasil',
        )
      } else {
        this.filteredLoincCode = this.loincCode.filter(
          (item) =>
            item['Nama Pemeriksaan'].toLowerCase().includes(filterValue) &&
            item['Permintaan/Hasil'].trim() !== 'Permintaan',
        )
      }
    },
    handleRowClick(record) {
      // console.log(record)
      if (this.loincType === 'master') {
        this.masterExamination.name = record['Nama Pemeriksaan']
        this.masterExamination.loincCode = record.Code
      } else if (this.loincType === 'component') {
        this.components[this.selectedComponentId].indicators = []
        this.components[this.selectedComponentId].answerList = []
        this.components[this.selectedComponentId].loincAnswerList = []
        this.components[this.selectedComponentId].name =
          record['Nama Pemeriksaan']
        this.components[this.selectedComponentId].resultType =
          record['Tipe hasil pemeriksaan']
        this.components[this.selectedComponentId].loincCode = record.Code
        if (record.Satuan) {
          this.components[this.selectedComponentId].unit = record.Satuan
        }
        if (
          record['Tipe hasil pemeriksaan'] === 'Quantitative' ||
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          if (!this.components[this.selectedComponentId].indicators.length) {
            this.addIndicator(this.selectedComponentId)
          }
        }
        console.log(record['Tipe hasil pemeriksaan'], 'emang ada disini?')

        if (
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          const filteredData = this.loincAnswer.filter(
            (e) =>
              e['LOINC Code'] ===
              this.components[this.selectedComponentId].loincCode,
          )
          console.log(filteredData, 'inifilteranswer')
          this.components[this.selectedComponentId].loincAnswerList =
            filteredData // Tetap menggunakan referensi asli untuk loincAnswerList
          this.components[this.selectedComponentId].answerList = JSON.parse(
            JSON.stringify(filteredData),
          ) //
        }
      }
      this.modalVisible = !this.modalVisible
      // this.$router.push('/')
    },
    handleData(dataType, actionType, index, indexIndicator) {
      if (dataType === 'component') {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.components.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index + 1])
            this.$set(this.components, index + 1, temp)
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index - 1])
            this.$set(this.components, index - 1, temp)
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          this.components.splice(index, 1)
        }
      } else {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (indexIndicator < this.components[index].indicators.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator + 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator + 1,
              temp,
            )
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (indexIndicator > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator - 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator - 1,
              temp,
            )
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          this.components[index].indicators.splice(indexIndicator, 1)
        }
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>

<template>
  <div>
    <div class="card custom-card">
      <div class="card-body w-100">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Formulir Registrasi Pasien Baru
        </div>
        <!-- <a-form> -->

        <a-form-item
          label="Jenis Kunjungan"
          class="no-gap custom-label-width"
          value="small"
        >
          <a-dropdown class="custom-dropdown" disabled>
            <template #overlay>
              <a-menu @click="handleKunjunganClick">
                <a-menu-item value="Rawat Inap">Rawat Jalan</a-menu-item>
                <a-menu-item value="Rawat Jalan">Rawat Inap</a-menu-item>
              </a-menu>
            </template>
            <a-button class="custom-dropdown-flex">
              {{ formData.kunjungan || 'Pilih Jenis Kunjungan' }}
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </a-form-item>

        <!-- <a-form-item label="Identitas Pasien">
                        <a-dropdown class="custom-dropdown mb-4">
                            <template #overlay>
                                <a-menu @click="handleIdentitasPasienClick">
                                <a-menu-item key="Orang Umum">Orang Umum</a-menu-item>
                                <a-menu-item key="Bayi Baru Lahir">Bayi Baru Lahir</a-menu-item>
                                </a-menu>
                            </template>
                            <a-button class="custom-dropdown-flex">
                                {{ formData.patient_type || 'Pilih Identitas Pasien' }}
                                <a-icon type="down" />
                            </a-button>
                        </a-dropdown>
                    </a-form-item> -->

        <a-form-item label="Identitas Pasien">
          <a-select
            placeholder="Pilih Identitas Pasien"
            v-decorator="[
              'patient_type',
              {
                rules: [
                  {
                    required: true,
                    message: 'Identitas Pasien harus dipilih!',
                  },
                ],
              },
            ]"
            v-model="formData.patient_type"
            @change="handleIdentitasPasienClick"
            style="width: 100%"
          >
            <a-select-option key="Orang Umum" value="Orang Umum"
              >Orang Umum</a-select-option
            >
            <a-select-option key="Bayi Baru Lahir" value="Bayi Baru Lahir"
              >Bayi Baru Lahir</a-select-option
            >
          </a-select>
        </a-form-item>

        <div v-if="this.formData.kunjungan && this.formData.patient_type">
          <a-steps :current="currentStep - 1" class="steps mb-4" progress-dot>
            <a-step
              v-for="(step, index) in steps"
              :key="index"
              :title="step.title"
            />
          </a-steps>

          <div v-if="currentStep === 1">
            <div class="h4 font-weight-bold text-dark">Identitas Pasien</div>
            <a-form :form="form1" class="form">
              <a-form-item>
                <div>Nama Lengkap : <span class="text-danger">*</span></div>
                <!-- <a-input v-model="formData.name" placeholder="Nama lengkap sesuai identitas" /> -->
                <a-input
                  v-decorator="[
                    'name',
                    {
                      initialValue: formData.name,
                      rules: [
                        {
                          required: true,
                          message: 'Nama lengkap harus diisi!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Nama lengkap sesuai identitas"
                />
              </a-form-item>
              <a-form-item>
                <div>
                  Nomor Induk Kependudukan (NIK) :
                  <span class="text-danger">*</span>
                </div>
                <!-- <a-input v-model="formData.NIK" placeholder="NIK" /> -->
                <a-input
                  v-decorator="[
                    'NIK',
                    {
                      initialValue: formData.NIK,
                      rules: [
                        { required: true, message: 'NIK harus diisi!' },
                        {
                          pattern: /^\d{16}$/,
                          message: 'NIK harus berisi 16 angka!',
                        },
                      ],
                    },
                  ]"
                  placeholder="NIK"
                />
              </a-form-item>
              <a-form-item>
                <div>Nomor Identitas Lain :</div>
                <a-input
                  v-model="formData.another_identity"
                  placeholder="Nomor Paspor/KITAS (Khusus WNA)"
                />
              </a-form-item>
              <a-form-item>
                <div>Nama Ibu Kandung : <span class="text-danger">*</span></div>
                <!-- <a-input v-model="formData.biological_mother_name" placeholder="Nama ibu kandung" /> -->
                <a-input
                  v-decorator="[
                    'biological_mother_name',
                    {
                      initialValue: formData.biological_mother_name,
                      rules: [
                        {
                          required: true,
                          message: 'Nama ibu kandung harus diisi!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Nama ibu kandung"
                />
              </a-form-item>
              <div class="d-flex flex-row justify-content-between">
                <a-form-item style="width: 325px">
                  <div>Tempat Lahir : <span class="text-danger">*</span></div>
                  <!-- <a-input v-model="formData.birth_place" placeholder="Tempat lahir sesuai identitas" /> -->
                  <a-input
                    v-decorator="[
                      'birth_place',
                      {
                        initialValue: formData.birth_place,
                        rules: [
                          {
                            required: true,
                            message: 'Tempat lahir harus diisi!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Tempat lahir sesuai identitas"
                  />
                </a-form-item>
                <a-form-item style="width: 325px">
                  <div>Tanggal Lahir : <span class="text-danger">*</span></div>
                  <!-- <a-date-picker v-model="formData.birth_date" placeholder="Tanggal Lahir" style="width:100%"/> -->
                  <a-date-picker
                    v-decorator="[
                      'birth_date',
                      {
                        initialValue: formData.birth_date,
                        rules: [
                          {
                            required: true,
                            message: 'Tanggal lahir harus diisi!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Tanggal Lahir"
                    style="width: 100%"
                  />
                </a-form-item>
              </div>
              <a-form-item>
                <div>Jenis Kelamin : <span class="text-danger">*</span></div>
                <!-- <a-dropdown class="custom-dropdown">
                                        <template #overlay>
                                            <a-menu @click="handleGenderClick">
                                            <a-menu-item key="Male">Laki-laki</a-menu-item>
                                            <a-menu-item key="Female">Perempuan</a-menu-item>
                                            </a-menu>
                                        </template>
                                        <a-button class="custom-dropdown-flex">
                                            {{ formData.gender || 'Jenis Kelamin' }}
                                            <a-icon type="down" />
                                        </a-button>
                                    </a-dropdown> -->
                <a-select
                  v-decorator="[
                    'gender',
                    {
                      initialValue: formData.gender,
                      rules: [
                        {
                          required: true,
                          message: 'Jenis Kelamin harus diisi!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Jenis Kelamin"
                >
                  <a-select-option value="Male">Laki-laki</a-select-option>
                  <a-select-option value="Female">Perempuan</a-select-option>
                </a-select>
              </a-form-item>
              <div
                class="d-flex flex-row justify-content-between"
                style="flex-wrap: wrap"
              >
                <div>
                  <div>Nomor Telepon Rumah :</div>

                  <div class="d-flex align-items-center" style="width: 325px">
                    <a-form-item class="col-xl-4">
                      <a-select
                        v-decorator="[
                          'telephone_no_code',
                          {
                            initialValue: '+62',
                            rules: [
                              {
                                required: true,
                                message: 'Kode negara harus dipilih!',
                              },
                            ],
                          },
                        ]"
                        placeholder="Kode Negara"
                        style="width: 100%"
                      >
                        <!-- <a-select-option value="+62">+62</a-select-option> -->
                        <a-select-option
                          v-for="code in phoneCode"
                          :key="code.country"
                          :value="code.code"
                        >
                          {{ code.code }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="col-xl-8">
                      <!-- <a-input v-model="formData.telephone_no" placeholder="+62 0000000000" /> -->
                      <a-input
                        v-decorator="[
                          'telephone_no',
                          {
                            initialValue: formData.telephone_no,
                            rules: [
                              {
                                pattern: /^\d{5,}$/,
                                message: 'Nomor telepon tidak valid!',
                              },
                            ],
                          },
                        ]"
                        placeholder="0000000000"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div></div>
                <div>
                  <div>
                    Nomor Telepon Seluler : <span class="text-danger">*</span>
                  </div>

                  <div class="d-flex align-items-center" style="width: 325px">
                    <a-form-item class="col-xl-4">
                      <a-select
                        v-decorator="[
                          'phone_no_code',
                          {
                            initialValue: '+62',
                            rules: [
                              {
                                required: true,
                                message: 'Kode negara harus dipilih!',
                              },
                            ],
                          },
                        ]"
                        placeholder="Kode Negara"
                        style="width: 100%"
                      >
                        <!-- <a-select-option value="+62">+62</a-select-option> -->
                        <a-select-option
                          v-for="code in phoneCode"
                          :key="code.country"
                          :value="code.code"
                        >
                          {{ code.code }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="col-xl-8">
                      <!-- <a-input v-model="formData.phone_no" placeholder="+62 0000000000" /> -->
                      <div class="d-flex">
                        <a-input
                          v-decorator="[
                            'phone_no',
                            {
                              initialValue: formData.phone_no,
                              rules: [
                                {
                                  initialValue: formData.phone_no,
                                  required: true,
                                  message: 'Nomor telepon seluler harus diisi!',
                                },
                                {
                                  pattern: /^\d{5,}$/,
                                  message: 'Nomor telepon tidak valid!',
                                },
                              ],
                            },
                          ]"
                          placeholder="0000000000"
                        />
                      </div>
                    </a-form-item>
                  </div>
                </div>
              </div>
              <a-form-item>
                <div>Email :</div>
                <!-- <a-input v-model="formData.email" placeholder="Alamat Email" /> -->
                <a-input
                  v-decorator="[
                    'email',
                    {
                      initialValue: formData.email,
                      rules: [
                        { type: 'email', message: 'Alamat Email Tidak Valid' },
                      ],
                    },
                  ]"
                  placeholder="Alamat Email"
                />
              </a-form-item>
              <a-form-item>
                <div class="one-button-container">
                  <button
                    type="button"
                    @click="nextStep"
                    class="button next-button"
                  >
                    Selanjutnya
                  </button>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div v-if="currentStep === 2">
            <div class="h4 font-weight-bold text-dark">Alamat Identitas</div>
            <a-form :form="form2">
              <a-form-item>
                <div>Negara : <span class="text-danger">*</span></div>
                <!-- <a-dropdown class="custom-dropdown">
                                    <template #overlay>
                                        <a-menu @click="handleCountryClick">
                                            <a-menu-item v-for="country in countries" :key="country" :value="country">
                                                {{ country }}
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                    <a-button class="custom-dropdown-flex">
                                        {{ formData.identity_nation || 'Pilih Negara' }}
                                        <a-icon type="down" />
                                    </a-button>
                                </a-dropdown> -->
                <a-select
                  v-decorator="[
                    'identity_nation',
                    {
                      initialValue: formData.identity_nation,
                      rules: [
                        { required: true, message: 'Negara harus dipilih!' },
                      ],
                    },
                  ]"
                  v-model="formData.identity_nation"
                  placeholder="Pilih Negara"
                >
                  <a-select-option
                    v-for="country in countries"
                    :key="country"
                    :value="country"
                  >
                    {{ country }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <div v-if="formData.identity_nation === 'Indonesia'">
                <!-- <a-form-item>
                                    <div>Provinsi : <span class="text-danger">*</span></div>
                                    <a-dropdown class="custom-dropdown">
                                        <template #overlay>
                                            <a-menu @click="handleProvinceClick">
                                                <a-menu-item v-for="province in provinces" :key="province.id" :value="province">
                                                    {{ province.nama }}
                                                </a-menu-item>
                                            </a-menu>
                                        </template>
                                        <a-button class="custom-dropdown-flex">
                                            {{ formData.identity_province || 'Pilih Provinsi' }}
                                            <a-icon type="down" />
                                        </a-button>
                                    </a-dropdown>
                                </a-form-item> -->
                <a-form-item>
                  <div>Provinsi : <span class="text-danger">*</span></div>
                  <a-select
                    v-decorator="[
                      'identity_province',
                      {
                        initialValue: formData.identity_province,
                        rules: [
                          {
                            required: true,
                            message: 'Provinsi harus dipilih!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Pilih Provinsi"
                    @change="handleProvinceClick"
                  >
                    <a-select-option
                      v-for="province in this.provinces"
                      :key="province.id"
                      :value="province.id"
                    >
                      {{ province.nama }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <!-- <a-form-item>
                                    <div>Kota / Kabupaten : <span class="text-danger">*</span></div>
                                    <a-dropdown class="custom-dropdown">
                                        <template #overlay>
                                            <a-menu @click="handleRegencyClick">
                                                <a-menu-item v-for="regency in regencies" :key="regency.id" :value="regency">
                                                    {{ regency.nama }}
                                                </a-menu-item>
                                            </a-menu>
                                        </template>
                                        <a-button class="custom-dropdown-flex">
                                            {{ formData.identity_regency || 'Pilih Kota/Kabupaten' }}
                                            <a-icon type="down" />
                                        </a-button>
                                    </a-dropdown>
                                </a-form-item> -->

                <a-form-item>
                  <div>
                    Kota / Kabupaten : <span class="text-danger">*</span>
                  </div>
                  <a-select
                    v-decorator="[
                      'identity_regency',
                      {
                        initialValue: formData.identity_regency,
                        rules: [
                          {
                            required: true,
                            message: 'Kota / Kabupaten harus dipilih!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Pilih Kota / Kabupaten"
                    @change="handleRegencyClick"
                  >
                    <a-select-option
                      v-for="regency in this.regencies"
                      :key="regency.id"
                      :value="regency.id"
                    >
                      {{ regency.nama }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <div class="d-flex flex-row justify-content-between">
                  <!-- <a-form-item style="width: 325px;">
                                        <div>Kecamatan : <span class="text-danger">*</span></div>
                                        <a-dropdown class="custom-dropdown">
                                            <template #overlay>
                                                <a-menu @click="handleDistrictClick">
                                                    <a-menu-item v-for="district in districts" :key="district.id" :value="district">
                                                        {{ district.nama }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </template>
                                            <a-button class="custom-dropdown-flex">
                                                {{ formData.identity_district || 'Pilih Kecamatan' }}
                                                <a-icon type="down" />
                                            </a-button>
                                        </a-dropdown>
                                    </a-form-item> -->
                  <a-form-item style="width: 325px">
                    <div>Kecamatan : <span class="text-danger">*</span></div>
                    <a-select
                      v-decorator="[
                        'identity_district',
                        {
                          initialValue: formData.identity_district,
                          rules: [
                            {
                              required: true,
                              message: 'Kecamatan harus dipilih!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Pilih Kecamatan"
                      @change="handleDistrictClick"
                    >
                      <a-select-option
                        v-for="district in this.districts"
                        :key="district.id"
                        :value="district.id"
                      >
                        {{ district.nama }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <!-- <a-form-item style="width: 325px;">
                                        <div>Kelurahan / Desa : <span class="text-danger">*</span></div>
                                        <a-dropdown class="custom-dropdown">
                                            <template #overlay>
                                                <a-menu @click="handleVillageClick">
                                                    <a-menu-item v-for="village in villages" :key="village.id" :value="village">
                                                        {{ village.nama }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </template>
                                            <a-button class="custom-dropdown-flex">
                                                {{ formData.identity_village || 'Pilih Kelurahan' }}
                                                <a-icon type="down" />
                                            </a-button>
                                        </a-dropdown>
                                    </a-form-item> -->
                  <a-form-item style="width: 325px">
                    <!-- <div>Kelurahan / Desa : <span class="text-danger">*</span></div>
                                        <a-select 
                                            v-decorator="['identity_village', { 
                                                rules: [ 
                                                    { required: true, message: 'Kelurahan / Desa harus dipilih' }
                                                ] 
                                            }]"
                                            placeholder="Pilih Kelurahan / Desa" 
                                            @change="handleVillageClick"
                                        >
                                            <a-select-option v-for="village in this.villages" :key="village.id" :value="village.id">
                                            {{ village.nama }}
                                            </a-select-option>
                                        </a-select> -->
                    <div>
                      Kelurahan / Desa : <span class="text-danger">*</span>
                    </div>
                    <a-select
                      v-decorator="[
                        'identity_village',
                        {
                          initialValue: formData.identity_village,
                          rules: [
                            {
                              required: true,
                              message: 'Kelurahan / Desa harus dipilih!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Pilih Kelurahan / Desa"
                      @change="handleVillageClick"
                    >
                      <a-select-option
                        v-for="village in this.villages"
                        :key="village.id"
                        :value="village.id"
                      >
                        {{ village.nama }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>

                <a-form-item>
                  <div>Alamat Lengkap : <span class="text-danger">*</span></div>
                  <!-- <a-input v-model="formData.identity_address" placeholder="Alamat pasien sesuai identitas" /> -->
                  <a-input
                    v-decorator="[
                      'identity_address',
                      {
                        initialValue: formData.identity_address,
                        rules: [
                          { required: true, message: 'Alamat harus diisi!' },
                        ],
                      },
                    ]"
                    placeholder="Alamat pasien sesuai identitas"
                  />
                </a-form-item>

                <div class="d-flex flex-row justify-content-between">
                  <a-form-item style="width: 325px">
                    <div>RT : <span class="text-danger">*</span></div>
                    <!-- <a-input v-model="formData.identity_RT" placeholder="000" /> -->
                    <a-input
                      v-decorator="[
                        'identity_RT',
                        {
                          initialValue: formData.identity_RT,
                          rules: [
                            { required: true, message: 'RT harus diisi!' },
                            {
                              pattern: /^\d{1,}$/,
                              message: 'RT harus berisi angka!',
                            },
                          ],
                        },
                      ]"
                      placeholder="000"
                    />
                  </a-form-item>
                  <a-form-item style="width: 325px">
                    <div>RW : <span class="text-danger">*</span></div>
                    <!-- <a-input v-model="formData.identity_RW" placeholder="000" /> -->
                    <a-input
                      v-decorator="[
                        'identity_RW',
                        {
                          initialValue: formData.identity_RW,
                          rules: [
                            { required: true, message: 'RW harus diisi!' },
                            {
                              pattern: /^\d{1,}$/,
                              message: 'RW harus berisi angka!',
                            },
                          ],
                        },
                      ]"
                      placeholder="000"
                    />
                  </a-form-item>
                </div>

                <a-form-item>
                  <div>Kode Pos : <span class="text-danger">*</span></div>
                  <!-- <a-input v-model="formData.identity_postal_code" placeholder="Kode pos sesuai identitas" /> -->
                  <a-input
                    v-decorator="[
                      'identity_postal_code',
                      {
                        initialValue: formData.identity_postal_code,
                        rules: [
                          { required: true, message: 'Kode Pos harus diisi!' },
                          {
                            pattern: /^\d{5}$/,
                            message: 'Kode Pos harus berisi 5 angka!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Kode pos sesuai identitas"
                  />
                </a-form-item>
              </div>

              <div v-if="formData.identity_nation === 'Luar Negeri'">
                <a-form-item label="Nama Negara">
                  <a-input
                    v-model="formData.identity_nationName"
                    placeholder="Negara pasien sesuai identitas"
                  />
                </a-form-item>
                <a-form-item>
                  <div>Alamat Lengkap : <span class="text-danger">*</span></div>
                  <a-textarea
                    v-model="formData.identity_addressForeign"
                    placeholder="Alamat pasien sesuai identitas (Jalan, nomor rumah, kota, dan kode pos)"
                    rows="4"
                  />
                </a-form-item>
              </div>

              <a-form-item v-if="formData.identity_nation !== ''">
                <div class="two-button-container">
                  <button @click="prevStep" class="button prev-button">
                    Kembali
                  </button>
                  <button
                    type="primary"
                    @click="nextStep"
                    class="button next-button"
                  >
                    Selanjutnya
                  </button>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div v-if="currentStep === 3">
            <div class="h4 font-weight-bold text-dark">Alamat Domisili</div>
            <a-form :form="form3">
              <a-form-item
                label="Alamat Domisili sama dengan Alamat Identitas?"
              >
                <a-radio-group v-model="formData.isAddressSame">
                  <a-radio value="true">Ya</a-radio>
                  <a-radio value="false">Tidak</a-radio>
                </a-radio-group>
              </a-form-item>

              <div v-if="this.formData.isAddressSame === 'false'">
                <a-form-item>
                  <div>Negara : <span class="text-danger">*</span></div>
                  <!-- <a-dropdown class="custom-dropdown">
                                        <template #overlay>
                                            <a-menu @click="handleCountryClick">
                                                <a-menu-item v-for="country in countries" :key="country" :value="country">
                                                    {{ country }}
                                                </a-menu-item>
                                            </a-menu>
                                        </template>
                                        <a-button class="custom-dropdown-flex">
                                            {{ formData.domicile_nation || 'Pilih Negara' }}
                                            <a-icon type="down" />
                                        </a-button>
                                    </a-dropdown> -->
                  <a-select
                    v-decorator="[
                      'domicile_nation',
                      {
                        initialValue: formData.domicile_nation,
                        rules: [
                          { required: true, message: 'Negara harus dipilih!' },
                        ],
                      },
                    ]"
                    v-model="formData.domicile_nation"
                    placeholder="Pilih Negara"
                  >
                    <a-select-option
                      v-for="country in countries"
                      :key="country"
                      :value="country"
                    >
                      {{ country }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <div v-if="formData.domicile_nation === 'Indonesia'">
                  <a-form-item>
                    <div>Provinsi : <span class="text-danger">*</span></div>
                    <!-- <a-dropdown class="custom-dropdown">
                                            <template #overlay>
                                                <a-menu @click="handleProvinceClick">
                                                    <a-menu-item v-for="province in provinces" :key="province.id" :value="province">
                                                        {{ province.nama }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </template>
                                            <a-button class="custom-dropdown-flex">
                                                {{ formData.domicile_province || 'Pilih Provinsi' }}
                                                <a-icon type="down" />
                                            </a-button>
                                        </a-dropdown> -->
                    <a-select
                      v-decorator="[
                        'domicile_province',
                        {
                          initialValue: formData.domicile_province,
                          rules: [
                            {
                              required: true,
                              message: 'Provinsi harus dipilih!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Pilih provinsi"
                      @change="handleProvinceClick"
                    >
                      <a-select-option
                        v-for="province in this.provinces"
                        :key="province.id"
                        :value="province.id"
                      >
                        {{ province.nama }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item>
                    <div>
                      Kota / Kabupaten : <span class="text-danger">*</span>
                    </div>
                    <!-- <a-dropdown class="custom-dropdown">
                                            <template #overlay>
                                                <a-menu @click="handleRegencyClick">
                                                    <a-menu-item v-for="regency in regencies" :key="regency.id" :value="regency">
                                                        {{ regency.nama }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </template>
                                            <a-button class="custom-dropdown-flex">
                                                {{ formData.domicile_regency || 'Pilih Kota/Kabupaten' }}
                                                <a-icon type="down" />
                                            </a-button>
                                        </a-dropdown> -->
                    <a-select
                      v-decorator="[
                        'domicile_regency',
                        {
                          initialValue: formData.domicile_regency,
                          rules: [
                            {
                              required: true,
                              message: 'Kota / Kabupaten harus dipilih!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Pilih kota / kabupaten"
                      @change="handleRegencyClick"
                    >
                      <a-select-option
                        v-for="regency in this.regencies"
                        :key="regency.id"
                        :value="regency.id"
                      >
                        {{ regency.nama }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <div class="d-flex flex-row justify-content-between">
                    <a-form-item style="width: 325px">
                      <div>Kecamatan : <span class="text-danger">*</span></div>
                      <!-- <a-dropdown class="custom-dropdown">
                                                <template #overlay>
                                                    <a-menu @click="handleDistrictClick">
                                                        <a-menu-item v-for="district in districts" :key="district.id" :value="district">
                                                            {{ district.nama }}
                                                        </a-menu-item>
                                                    </a-menu>
                                                </template>
                                                <a-button class="custom-dropdown-flex">
                                                    {{ formData.domicile_district || 'Pilih Kecamatan' }}
                                                    <a-icon type="down" />
                                                </a-button>
                                            </a-dropdown> -->
                      <a-select
                        v-decorator="[
                          'domicile_district',
                          {
                            initialValue: formData.domicile_district,
                            rules: [
                              {
                                required: true,
                                message: 'Provinsi harus dipilih!',
                              },
                            ],
                          },
                        ]"
                        placeholder="Pilih provinsi"
                        @change="handleDistrictClick"
                      >
                        <a-select-option
                          v-for="district in this.districts"
                          :key="district.id"
                          :value="district.id"
                        >
                          {{ district.nama }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>

                    <a-form-item style="width: 325px">
                      <div>
                        Kelurahan / Desa : <span class="text-danger">*</span>
                      </div>
                      <!-- <a-dropdown class="custom-dropdown">
                                                <template #overlay>
                                                    <a-menu @click="handleVillageClick">
                                                        <a-menu-item v-for="village in villages" :key="village.id" :value="village">
                                                            {{ village.nama }}
                                                        </a-menu-item>
                                                    </a-menu>
                                                </template>
                                                <a-button class="custom-dropdown-flex">
                                                    {{ formData.domicile_village || 'Pilih Kelurahan' }}
                                                    <a-icon type="down" />
                                                </a-button>
                                            </a-dropdown> -->
                      <a-select
                        v-decorator="[
                          'domicile_village',
                          {
                            initialValue: formData.domicile_village,
                            rules: [
                              {
                                required: true,
                                message: 'Provinsi harus dipilih!',
                              },
                            ],
                          },
                        ]"
                        placeholder="Pilih provinsi"
                        @change="handleVillageClick"
                      >
                        <a-select-option
                          v-for="village in this.villages"
                          :key="village.id"
                          :value="village.id"
                        >
                          {{ village.nama }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>

                  <a-form-item>
                    <div>
                      Alamat Lengkap : <span class="text-danger">*</span>
                    </div>
                    <!-- <a-input v-model="formData.domicile_address" placeholder="Alamat pasien sesuai identitas" /> -->
                    <a-input
                      v-decorator="[
                        'domicile_address',
                        {
                          initialValue: formData.domicile_address,
                          rules: [
                            { required: true, message: 'Alamat harus diisi!' },
                          ],
                        },
                      ]"
                      placeholder="Alamat pasien sesuai identitas"
                    />
                  </a-form-item>

                  <div class="d-flex flex-row justify-content-between">
                    <a-form-item style="width: 325px">
                      <div>RT : <span class="text-danger">*</span></div>
                      <!-- <a-input v-model="formData.domicile_RT" placeholder="000" /> -->
                      <a-input
                        v-decorator="[
                          'domicile_RT',
                          {
                            initialValue: formData.domicile_RT,
                            rules: [
                              { required: true, message: 'RT harus diisi!' },
                              {
                                pattern: /^\d{1,}$/,
                                message: 'RT harus berisi angka!',
                              },
                            ],
                          },
                        ]"
                        placeholder="000"
                      />
                    </a-form-item>
                    <a-form-item style="width: 325px">
                      <div>RW : <span class="text-danger">*</span></div>
                      <!-- <a-input v-model="formData.domicile_RW" placeholder="000" /> -->
                      <a-input
                        v-decorator="[
                          'domicile_RW',
                          {
                            initialValue: formData.domicile_RW,
                            rules: [
                              { required: true, message: 'RW harus diisi!' },
                              {
                                pattern: /^\d{1,}$/,
                                message: 'RW harus berisi angka!',
                              },
                            ],
                          },
                        ]"
                        placeholder="000"
                      />
                    </a-form-item>
                  </div>

                  <a-form-item>
                    <div>Kode Pos : <span class="text-danger">*</span></div>
                    <!-- <a-input v-model="formData.domicile_postal_code" placeholder="Kode pos sesuai domisili" /> -->
                    <a-input
                      v-decorator="[
                        'domicile_postal_code',
                        {
                          initialValue: formData.domicile_postal_code,
                          rules: [
                            {
                              required: true,
                              message: 'Kode Pos harus diisi!',
                            },
                            {
                              pattern: /^\d{5}$/,
                              message: 'Kode Pos harus berisi 5 angka!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Kode pos sesuai domisili"
                    />
                  </a-form-item>
                </div>

                <div v-if="formData.domicile_nation === 'Luar Negeri'">
                  <a-form-item label="Nama Negara">
                    <a-input
                      v-model="formData.domicile_nationName"
                      placeholder="Provinsi pasien sesuai identitas"
                    />
                  </a-form-item>
                  <a-form-item>
                    <div>
                      Alamat Lengkap : <span class="text-danger">*</span>
                    </div>
                    <a-textarea
                      v-model="formData.domicile_addressForeign"
                      placeholder="Alamat pasien sesuai identitas (Jalan, nomor rumah, kota, dan kode pos)"
                      rows="4"
                    />
                  </a-form-item>
                </div>
              </div>
              <a-form-item
                v-if="
                  this.formData.isAddressSame === 'true' ||
                  (this.formData.isAddressSame === 'false' &&
                    this.formData.domicile_nation !== '')
                "
              >
                <div class="two-button-container">
                  <button @click="prevStep" class="button prev-button">
                    Kembali
                  </button>
                  <button
                    type="primary"
                    @click="nextStep"
                    class="button next-button"
                  >
                    Selanjutnya
                  </button>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div v-if="currentStep === 4">
            <div class="h4 font-weight-bold text-dark">Informasi Lainnya</div>
            <a-form :form="form4">
              <a-form-item>
                <div>Agama : <span class="text-danger">*</span></div>
                <!-- <a-dropdown class="custom-dropdown">
                                    <template #overlay>
                                        <a-menu @click="handleReligionClick">
                                            <a-menu-item v-for="religion in religions" :key="religion" :value="religion">
                                                {{ religion }}
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                    <a-button class="custom-dropdown-flex">
                                        {{ formData.religion || 'Agama' }}
                                        <a-icon type="down" />
                                    </a-button>
                                </a-dropdown> -->

                <a-select
                  v-decorator="[
                    'religion',
                    {
                      initialValue: formData.religion,
                      rules: [
                        { required: true, message: 'Agama harus dipilih!' },
                      ],
                    },
                  ]"
                  v-model="formData.religion"
                  placeholder="Pilih Agama"
                >
                  <a-select-option
                    v-for="religion in religions"
                    :key="religion"
                    :value="religion"
                  >
                    {{ religion }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <div v-if="this.formData.religion === 'Lain-lain'">
                <a-form-item label="Agama Lainnya">
                  <a-input
                    v-model="formData.otherReligion"
                    placeholder="Isi agama di sini"
                  />
                </a-form-item>
              </div>

              <a-form-item>
                <div>Suku :</div>
                <a-input v-model="formData.ethnic" placeholder="Suku" />
              </a-form-item>

              <a-form-item>
                <div>
                  Bahasa yang Dikuasai : <span class="text-danger">*</span>
                </div>
                <!-- <a-input v-model="formData.language" placeholder="Bahasa komunikasi pasien" /> -->
                <a-input
                  v-decorator="[
                    'language',
                    {
                      initialValue: formData.language,
                      rules: [
                        { required: true, message: 'Bahasa harus diisi!' },
                      ],
                    },
                  ]"
                  placeholder="Bahasa komunikasi pasien"
                />
              </a-form-item>

              <a-form-item>
                <div>Pendidikan :</div>
                <!-- <a-dropdown class="custom-dropdown">
                                    <template #overlay>
                                        <a-menu @click="handleEducationClick">
                                            <a-menu-item v-for="education in educations" :key="education" :value="education">
                                                {{ education }}
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                    <a-button class="custom-dropdown-flex">
                                        {{ formData.education || 'Pendidikan' }}
                                        <a-icon type="down" />
                                    </a-button>
                                </a-dropdown> -->
                <a-select
                  v-decorator="[
                    'education',
                    { initialValue: formData.education },
                  ]"
                  v-model="formData.education"
                  placeholder="Pendidikan"
                >
                  <a-select-option
                    v-for="education in educations"
                    :key="education"
                    :value="education"
                  >
                    {{ education }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item>
                <div>Pekerjaan :</div>
                <a-select
                  v-decorator="['job', { initialValue: formData.job }]"
                  v-model="formData.job"
                  placeholder="Pekerjaan"
                >
                  <a-select-option v-for="job in jobs" :key="job" :value="job">
                    {{ job }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <div v-if="this.formData.job === 'Lain-lain'">
                <a-form-item label="Pekerjaan Lainnya">
                  <a-input
                    v-model="formData.otherJob"
                    placeholder="Isi pekerjaan di sini"
                  />
                </a-form-item>
              </div>

              <a-form-item>
                <div>
                  Status Pernikahan : <span class="text-danger">*</span>
                </div>
                <!-- <a-dropdown class="custom-dropdown">
                                    <template #overlay>
                                        <a-menu @click="handleMartialStatusClick">
                                            <a-menu-item v-for="martialStatus in martialStatus" :key="martialStatus" :value="martialStatus">
                                                {{ martialStatus }}
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                    <a-button class="custom-dropdown-flex">
                                        {{ formData.martial_status || 'Status pernikahan' }}
                                        <a-icon type="down" />
                                    </a-button>
                                </a-dropdown> -->
                <a-select
                  v-decorator="[
                    'martial_status',
                    {
                      initialValue: formData.martial_status,
                      rules: [
                        {
                          required: true,
                          message: 'Status pernikahan harus dipilih!',
                        },
                      ],
                    },
                  ]"
                  v-model="formData.martial_status"
                  placeholder="Status pernikahan"
                >
                  <a-select-option
                    v-for="martialStatus in martialStatus"
                    :key="martialStatus"
                    :value="martialStatus"
                  >
                    {{ martialStatus }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item>
                <div>Cara Pembayaran : <span class="text-danger">*</span></div>
                <!-- <a-dropdown class="custom-dropdown">
                                    <template #overlay>
                                        <a-menu @click="handlePaymentClick">
                                            <a-menu-item v-for="payment_method in payments" :key="payment_method.value" :value="payment_method.value">
                                                {{ payment_method.label }}
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                    <a-button class="custom-dropdown-flex">
                                        {{ formData.payment_method || 'Metode pembayaran pasien' }}
                                        <a-icon type="down" />
                                    </a-button>
                                </a-dropdown> -->
                <a-select
                  v-decorator="[
                    'payment_method',
                    {
                      initialValue: formData.payment_method,
                      rules: [
                        {
                          required: true,
                          message: 'Metode pembayaran harus dipilih!',
                        },
                      ],
                    },
                  ]"
                  v-model="formData.payment_method"
                  placeholder="Metode pembayaran pasien"
                >
                  <a-select-option
                    v-for="payment_method in payments"
                    :key="payment_method.value"
                    :value="payment_method.value"
                  >
                    {{ payment_method.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <div
                v-if="
                  this.formData.payment_method === 'JKN (BPJS Kesehatan)' ||
                  this.formData.payment_method === 'JKN'
                "
              >
                <a-form-item label="Nomor BPJS">
                  <a-input
                    v-model="formData.bpjs_no"
                    placeholder="Nomor BPJS"
                  />
                </a-form-item>
              </div>

              <div v-if="this.formData.payment_method === 'Asuransi'">
                <a-form-item label="Nama Asuransi">
                  <a-input
                    v-model="formData.insurance_name"
                    placeholder="Nama Asuransi"
                  />
                </a-form-item>
                <a-form-item label="Nomor Asuransi">
                  <a-input
                    v-model="formData.insurance_no"
                    placeholder="Nomor Asuransi"
                  />
                </a-form-item>
              </div>

              <a-form-item>
                <div class="two-button-container">
                  <button @click="prevStep" class="button prev-button">
                    Kembali
                  </button>
                  <button
                    v-if="
                      this.formData.patient_type === 'Orang Umum' ||
                      this.formData.patient_type === 'normal'
                    "
                    type="primary"
                    @click="openModal"
                    class="button next-button"
                  >
                    Simpan
                  </button>
                  <button
                    v-if="
                      this.formData.patient_type === 'Bayi Baru Lahir' ||
                      this.formData.patient_type === 'newborn'
                    "
                    type="primary"
                    @click="nextStep"
                    class="button next-button"
                  >
                    Selanjutnya
                  </button>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div v-if="currentStep === 5">
            <div class="h4 font-weight-bold text-dark">
              Identitas Bayi Baru Lahir
            </div>
            <a-form :form="form5">
              <a-form-item>
                <div>Nama Bayi : <span class="text-danger">*</span></div>
                <div>
                  Berupa tulisan "bayi" yang diikuti dengan nama lengkap ibu
                  sesuai kartu identitas
                </div>
                <!-- <a-input v-model="formData.baby_name" placeholder="Bayi Ny..." /> -->
                <a-input
                  v-decorator="[
                    'baby_name',
                    {
                      initialValue: formData.baby_name,
                      rules: [
                        { required: true, message: 'Nama bayi harus diisi!' },
                      ],
                    },
                  ]"
                  placeholder="Bayi Ny..."
                />
              </a-form-item>

              <a-form-item>
                <div>NIK Ibu Kandung : <span class="text-danger">*</span></div>
                <!-- <a-input v-model="formData.NIK_mother" placeholder="Nomor Induk Kependudukan ibu kandung" /> -->
                <a-input
                  v-decorator="[
                    'NIK_mother',
                    {
                      initialValue: formData.NIK,
                      rules: [
                        {
                          required: true,
                          message: 'NIK ibu kandung harus diisi!',
                        },
                        {
                          pattern: /^\d{16}$/,
                          message: 'NIK harus berisi 16 angka!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Nomor Induk Kependudukan ibu kandung"
                />
              </a-form-item>

              <div class="d-flex flex-row justify-content-between">
                <a-form-item>
                  <div>Tanggal Lahir : <span class="text-danger">*</span></div>
                  <!-- <a-date-picker v-model="formData.baby_birth_date" placeholder="Tanggal Lahir" style="width: 325px" /> -->
                  <a-date-picker
                    v-decorator="[
                      'baby_birth_date',
                      {
                        initialValue: formData.baby_birth_date,
                        rules: [
                          {
                            required: true,
                            message: 'Tanggal lahir harus diisi!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Tanggal Lahir"
                    style="width: 325px"
                  />
                </a-form-item>
                <a-form-item style="width: 325px">
                  <div>Jam Lahir : <span class="text-danger">*</span></div>
                  <!-- <a-input v-model="formData.baby_birth_time" placeholder="Jam:menit:detik" /> -->
                  <a-time-picker
                    v-decorator="[
                      'baby_birth_time',
                      {
                        initialValue: formData.baby_birth_time,
                        rules: [
                          { required: true, message: 'Jam lahir harus diisi!' },
                        ],
                      },
                    ]"
                    @change="checkDateBirth"
                    class="w-100"
                    placeholder="Pilih Jam"
                  />

                  <!-- <a-input
                    v-decorator="[
                      'baby_birth_time',
                      {
                        initialValue: formData.baby_birth_time,
                        rules: [
                          { required: true, message: 'Jam lahir harus diisi!' },
                          {
                            pattern: /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
                            message: 'Format jam lahir harus JJ:mm:dd',
                          },
                        ],
                      },
                    ]"
                    placeholder="Jam:menit:detik"
                  /> -->
                </a-form-item>
              </div>

              <a-form-item>
                <div>Jenis Kelamin : <span class="text-danger">*</span></div>
                <!-- <a-dropdown class="custom-dropdown">
                                    <template #overlay>
                                        <a-menu @click="handleGenderClick">
                                        <a-menu-item key="Male">Laki-laki</a-menu-item>
                                        <a-menu-item key="Female">Perempuan</a-menu-item>
                                        </a-menu>
                                    </template>
                                    <a-button class="custom-dropdown-flex">
                                        {{ formData.baby_gender || 'Jenis Kelamin' }}
                                        <a-icon type="down" />
                                    </a-button>
                                </a-dropdown> -->
                <a-select
                  v-decorator="[
                    'baby_gender',
                    {
                      initialValue: formData.baby_gender,
                      rules: [
                        {
                          required: true,
                          message: 'Jenis Kelamin harus dipilih!',
                        },
                      ],
                    },
                  ]"
                  v-model="formData.baby_gender"
                  placeholder="Jenis Kelamin"
                >
                  <a-select-option key="Male" value="Male"
                    >Laki-laki</a-select-option
                  >
                  <a-select-option key="Female" value="Female"
                    >Perempuan</a-select-option
                  >
                </a-select>
              </a-form-item>

              <a-form-item>
                <div class="two-button-container">
                  <button @click="prevStep" class="button prev-button">
                    Kembali
                  </button>
                  <button
                    type="primary"
                    @click="openModal"
                    class="button next-button"
                  >
                    Simpan
                  </button>
                </div>
              </a-form-item>
            </a-form>
          </div>
        </div>

        <a-modal
          v-model="this.isModalVisible"
          title="Yakin isi formulir sudah sesuai?"
          :confirm-loading="this.confirmLoading"
          @ok="submit"
          @cancel="closeModal"
          ok-text="Ya"
          cancel-text="Kembali"
        >
          <p>Mohon cek kembali data diri pasien sebelum menyimpan formulir.</p>
        </a-modal>
        <!-- </a-form> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProvince,
  getRegency,
  getDistrict,
  getVillage,
} from '@/services/axios/api/location'
import { create } from '@/services/axios/api/pasien'
import router from '../../../router'
import { getPhoneCode } from '@/services/axios/api/phoneCode'
import moment from 'moment'

export default {
  data() {
    return {
      form1: this.$form.createForm(this),
      form2: this.$form.createForm(this),
      form3: this.$form.createForm(this),
      form4: this.$form.createForm(this),
      form5: this.$form.createForm(this),
      currentStep: 1,
      formData: {
        kunjungan: 'Rawat Jalan',
        // patient_type: '',
        // gender: '',
        // isAddressSame: '',
        // identity_nation: '',
        // identity_province: '',
        // identity_regency: '',
        // identity_district: '',
        // identity_village: '',
        // identity_address: '',
        // identity_postal_code: '',
        // domicile_nation: '',
        // domicile_province: '',
        // domicile_regency: '',
        // domicile_district: '',
        // domicile_village: '',
        // domicile_address: '',
        // domicile_postal_code: '',
        // religion: '',
        // education: '',
        // job: '',
        // martial_status: '',
        // payment_method: '',
        // baby_gender: '',
      },
      provinces: [],
      regencies: [],
      districts: [],
      villages: [],
      selectedProvince: { id: null, name: null },
      selectedRegency: { id: null, name: null },
      selectedDistrict: { id: null, name: null },
      selectedVillage: { id: null, name: null },
      countries: ['Indonesia', 'Luar Negeri'],
      religions: [
        'Islam',
        'Kristen',
        'Katolik',
        'Hindu',
        'Budha',
        'Konghucu',
        'Penghayat',
        'Lain-lain',
      ],
      educations: [
        'Tidak sekolah',
        'SD',
        'SLTP sederajat',
        'SLTA sederajat',
        'D1-D3 sederajat',
        'D4',
        'S1',
        'S2',
        'S3',
      ],
      jobs: [
        'Tidak Bekerja',
        'PNS',
        'TNI/POLRI',
        'BUMN',
        'Pegawai Swasta / Wiraswasta',
        'Lain-lain',
      ],
      martialStatus: ['Belum Kawin', 'Kawin', 'Cerai Hidup', 'Cerai Mati'],
      payments: [
        {
          key: 'JKN (BPJS Kesehatan)',
          value: 'JKN',
          label: 'JKN (BPJS Kesehatan)',
        },
        {
          key: 'Mandiri',
          value: 'Mandiri',
          label: 'Mandiri',
        },
        {
          key: 'Asuransi',
          value: 'Asuransi',
          label: 'Asuransi',
        },
      ],
      isModalVisible: false,
      confirmLoading: false,
      identityLocation: {
        country: '',
        province: '',
        regency: '',
        district: '',
        village: '',
        address: '',
        RT: '',
        RW: '',
        postal_code: '',
      },
      domicileLocation: {
        country: '',
        province: '',
        regency: '',
        district: '',
        village: '',
        address: '',
        RT: '',
        RW: '',
        postal_code: '',
      },
      phoneCode: [],
    }
  },
  computed: {
    steps() {
      const baseSteps = [
        { title: 'Identitas Pasien' },
        { title: 'Alamat Identitas' },
        { title: 'Alamat Domisili' },
        { title: 'Informasi Lainnya' },
      ]

      if (this.formData.patient_type === 'Bayi Baru Lahir') {
        baseSteps.push({ title: 'Identitas Bayi Baru Lahir' })
      }
      return baseSteps
    },
  },
  created() {
    localStorage.removeItem('registrationData')
    const storedData = JSON.parse(localStorage.getItem('registrationData'))
    if (storedData) {
      this.formData = storedData
      // console.log('Stored Data:', storedData);
    }
    this.getPhoneCode()
  },
  methods: {
    checkDateBirth(value) {
      //   const date = new Date(value)
      console.log(new Date(value), 'cekini')
    },
    nextStep() {
      if (this.currentStep === 1) {
        this.form1.validateFields((err, values) => {
          if (!err) {
            this.formData.name = values.name
            this.formData.NIK = values.NIK
            this.formData.biological_mother_name = values.biological_mother_name
            this.formData.birth_place = values.birth_place
            this.formData.birth_date = values.birth_date
            this.formData.gender = values.gender
            this.formData.telephone_no = values.telephone_no
            this.formData.telephone_no_code = values.telephone_no_code
            this.formData.phone_no = values.phone_no
            this.formData.phone_no_code = values.phone_no_code
            this.formData.email = values.email
            this.currentStep++
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        })
      } else if (this.currentStep === 2) {
        this.form2.validateFields((err, values) => {
          if (!err) {
            this.formData.identity_nation = values.identity_nation
            this.formData.identity_address = values.identity_address
            this.formData.identity_RT = values.identity_RT
            this.formData.identity_RW = values.identity_RW
            this.formData.identity_postal_code = values.identity_postal_code
            this.currentStep++
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        })
      } else if (this.currentStep === 3) {
        if (this.formData.isAddressSame === 'true') {
          this.currentStep++
        } else {
          this.form3.validateFields((err, values) => {
            if (!err) {
              this.formData.domicile_nation = values.domicile_nation
              this.formData.domicile_address = values.domicile_address
              this.formData.domicile_RT = values.domicile_RT
              this.formData.domicile_RW = values.domicile_RW
              this.formData.domicile_postal_code = values.domicile_postal_code
              this.currentStep++
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
          })
        }
      } else if (this.currentStep === 4) {
        this.form4.validateFields((err, values) => {
          if (!err) {
            this.formData.language = values.language
            this.currentStep++
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        })
      }

      localStorage.setItem('registrationData', JSON.stringify(this.formData))
      // this.currentStep++;
      // console.log('storedData:', JSON.parse(localStorage.getItem('registrationData')));
      // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },
    prevStep() {
      this.currentStep--
    },
    closeModal() {
      this.isModalVisible = false
    },
    openModal() {
      if (this.currentStep === 4) {
        this.form4.validateFields((err, values) => {
          if (!err) {
            this.formData.language = values.language
            this.isModalVisible = true
          }
        })
      } else if (this.currentStep === 5) {
        this.form5.validateFields((err, values) => {
          if (!err) {
            this.formData.baby_name = values.baby_name
            this.formData.NIK_mother = values.NIK_mother
            this.formData.baby_birth_date = values.baby_birth_date
            this.formData.baby_birth_time = values.baby_birth_time
            this.formData.baby_gender = values.baby_gender
            this.isModalVisible = true
          }
        })
      }
      // this.isModalVisible = true;
    },
    async submit() {
      this.formData.baby_birth_time = moment(
        this.formData.baby_birth_time,
      ).format('HH:mm:ss') // Format dengan offset
      // if(this.currentStep != 5){
      // if(this.currentStep != 5){

      // }
      // const payload = {...this.formData}
      // payload.religion = payload.religion === 'Lain-lain' ? payload.otherReligion : payload.religion;
      this.confirmLoading = true

      this.formData.patient_type =
        this.formData.patient_type === 'Bayi Baru Lahir' ? 'newborn' : 'normal'
      // this.formData.gender = this.formData.gender === 'Laki-laki' ? 'Male' : 'Female'

      this.formData.religion =
        this.formData.religion === 'Lain-lain'
          ? this.formData.otherReligion
          : this.formData.religion
      this.formData.job =
        this.formData.job === 'Lain-lain'
          ? this.formData.otherJob
          : this.formData.job
      delete this.formData.otherReligion
      delete this.formData.otherJob

      if (this.formData.identity_nation === 'Luar Negeri') {
        this.formData.identity_nation = this.formData.identity_nationName
        this.formData.identity_address = this.formData.identity_addressForeign
        // this.formData.domicile_nation = this.formData.domicile_nationName
        delete this.formData.identity_nationName
        delete this.formData.identity_addressForeign
        // delete this.formData.domicile_nationName
      }

      if (this.formData.domicile_nation === 'Luar Negeri') {
        this.formData.domicile_nation = this.formData.domicile_nationName
        this.formData.domicile_address = this.formData.domicile_addressForeign
        delete this.formData.domicile_nationName
        delete this.formData.domicile_addressForeign
        delete this.formData.domicile_province
        delete this.formData.domicile_regency
        delete this.formData.domicile_district
        delete this.formData.domicile_village
      }

      if (this.formData.phone_no && this.formData.phone_no.startsWith('0')) {
        this.formData.phone_no = this.formData.phone_no.replace(/^0+/, '')
      }
      if (this.formData.phone_no && this.formData.phone_no.startsWith('+')) {
      } else {
        this.formData.phone_no =
          this.formData.phone_no_code + this.formData.phone_no
      }
      if (this.formData.telephone_no) {
        if (
          this.formData.telephone_no &&
          this.formData.telephone_no.startsWith('0')
        ) {
          this.formData.telephone_no = this.formData.telephone_no.replace(
            /^0+/,
            '',
          )
        }

        if (
          this.formData.telephone_no &&
          this.formData.telephone_no.startsWith('+')
        ) {
        } else {
          this.formData.telephone_no =
            this.formData.telephone_no_code + this.formData.telephone_no
        }
      }
      delete this.formData.phone_no_code
      delete this.formData.telephone_no_code

      this.formData.identity_province = this.identityLocation.province
      this.formData.identity_regency = this.identityLocation.regency
      this.formData.identity_district = this.identityLocation.district
      this.formData.identity_village = this.identityLocation.village

      if (this.formData.identity_nation !== 'Indonesia') {
        delete this.formData.identity_province
        delete this.formData.identity_regency
        delete this.formData.identity_district
        delete this.formData.identity_village
      }

      if (this.formData.isAddressSame === 'true') {
        this.formData.domicile_nation = this.formData.identity_nation
        this.formData.domicile_address = this.formData.identity_address
        if (this.formData.identity_nation === 'Indonesia') {
          this.formData.domicile_province = this.formData.identity_province
          this.formData.domicile_regency = this.formData.identity_regency
          this.formData.domicile_district = this.formData.identity_district
          this.formData.domicile_village = this.formData.identity_village
          this.formData.domicile_RT = this.formData.identity_RT
          this.formData.domicile_RW = this.formData.identity_RW
          this.formData.domicile_postal_code =
            this.formData.identity_postal_code
        }
      } else {
        this.formData.domicile_province = this.domicileLocation.province
        this.formData.domicile_regency = this.domicileLocation.regency
        this.formData.domicile_district = this.domicileLocation.district
        this.formData.domicile_village = this.domicileLocation.village
      }

      if (this.formData.payment_method === 'JKN (BPJS Kesehatan)') {
        this.formData.payment_method = 'JKN'
      }

      //   if (this.currentStep === 5) {
      //     const dateString = this.formData.baby_birth_date.toString()
      //     console.log(dateString, 'dateString')
      //     const dateCombine =
      //       dateString.split(' ')[0] +
      //       ' ' +
      //       dateString.split(' ')[1] +
      //       ' ' +
      //       dateString.split(' ')[2] +
      //       ' ' +
      //       dateString.split(' ')[3] +
      //       ' ' +
      //       this.formData.baby_birth_time +
      //       ' GMT+07:00'
      //     console.log(dateCombine, 'dateCombine')
      //     const toDate = new Date(dateCombine)
      //     console.log(toDate, 'toDate')
      //     const dateTimeISO = toDate.toISOString()
      //     console.log(dateTimeISO, 'dateTimeISO')

      //     this.formData.baby_birth_time = dateTimeISO

      //     // this.formData.baby_gender = this.formData.baby_gender === 'Laki-laki' ? 'Male' : 'Female';
      //   }

      console.log(this.formData.birth_date, 'iniasalnya')
      const birthDate = new Date(this.formData.birth_date)
      const localBirthDate = birthDate.toLocaleDateString('en-CA')
      this.formData.birth_date = localBirthDate
      console.log(this.formData.birth_date, 'iniafternya')

      localStorage.setItem('registrationData', JSON.stringify(this.formData))
      const finalData = JSON.parse(localStorage.getItem('registrationData'))
      // console.log('Final Data:', finalData);
      try {
        const response = await create(finalData)
        // console.log('Response:', response);
        if (response.status === 201) {
          this.$notification.success({
            message: 'Berhasil',
            description: 'Pasien baru berhasil ditambahkan',
            duration: 5,
          })
          router.push('/pasien')
        } else {
          this.$notification.error({
            message: 'Gagal',
            description: 'Data Pasien gagal ditambah, ' + response.data.message,
            duration: 5,
          })
        }
      } catch (error) {
        console.error('Error:', error)
      }

      this.confirmLoading = false
    },
    async fetchProvinces() {
      try {
        const data = await getProvince()
        // console.log('res', data)
        // console.log('Provinces:', data[0].nama);
        this.provinces = data
      } catch (error) {
        console.error('Error fetching provinces:', error)
      }
    },
    async fetchRegencies(id) {
      try {
        // console.log('Selected Province:', this.selectedProvince);
        const data = await getRegency(id)
        this.regencies = data
        // console.log('Regencies:', data)
      } catch (error) {
        console.error('Error fetching regencies:', error)
      }
    },
    async fetchDistricts(id) {
      try {
        // const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${this.selectedRegency.id}.json`);
        // const data = await response.json();
        const data = await getDistrict(id)
        this.districts = data
      } catch (error) {
        console.error('Error fetching districts:', error)
      }
    },
    async fetchVillages(id) {
      try {
        // const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${this.selectedDistrict.id}.json`);
        // const data = await response.json();
        const data = await getVillage(id)
        this.villages = data
      } catch (error) {
        console.error('Error fetching villages:', error)
      }
    },
    handleCountryClick({ key, item }) {
      this.selectedCountry = item.$slots.default[0].text.trim()

      if (this.currentStep === 2) {
        this.formData.identity_nation = this.selectedCountry
      }
      if (this.currentStep === 3) {
        this.formData.domicile_nation = this.selectedCountry
      }
    },
    // handleProvinceClick({key, item}) {
    //     this.selectedProvince.name = item.$slots.default[0].text.trim();
    //     this.selectedProvince.id = key;
    //     this.fetchRegencies();

    //     if (this.currentStep === 2){
    //         this.formData.identity_province = this.selectedProvince.name;
    //         this.identityLocation.province = this.selectedProvince.id;
    //     }
    //     if (this.currentStep === 3){
    //         this.formData.domicile_province = this.selectedProvince.name;
    //         this.domicileLocation.province = this.selectedProvince.id;
    //     }
    // },
    handleProvinceClick(value) {
      // console.log('Selected Province:', value)
      const searchProvince = this.provinces.find(
        (province) => province.id === value,
      )
      // console.log('Selected Province: uji coba', searchProvince)
      this.selectedProvince.id = value
      this.fetchRegencies(value)

      if (this.currentStep === 2) {
        this.formData.identity_province = searchProvince.nama
        this.identityLocation.province = value
      }
      if (this.currentStep === 3) {
        this.formData.domicile_province = searchProvince.nama
        this.domicileLocation.province = value
      }
    },
    // handleRegencyClick({key, item}) {
    //     this.selectedRegency.name = item.$slots.default[0].text.trim();
    //     this.selectedRegency.id = key;
    //     this.fetchDistricts();

    //     if (this.currentStep === 2){
    //         this.formData.identity_regency = this.selectedRegency.name;
    //         this.identityLocation.regency = this.selectedRegency.id;
    //     }
    //     if (this.currentStep === 3){
    //         this.formData.domicile_regency = this.selectedRegency.name;
    //         this.domicileLocation.regency = this.selectedRegency.id;
    //     }
    // },
    handleRegencyClick(value) {
      // console.log('Selected Regency:', value)
      const searchRegency = this.regencies.find(
        (regency) => regency.id === value,
      )
      this.selectedRegency.id = value
      this.fetchDistricts(value)

      if (this.currentStep === 2) {
        this.formData.identity_regency = searchRegency.nama
        this.identityLocation.regency = value
      }
      if (this.currentStep === 3) {
        this.formData.domicile_regency = searchRegency.nama
        this.domicileLocation.regency = value
      }
    },
    // handleDistrictClick({key, item}) {
    //     this.selectedDistrict.name = item.$slots.default[0].text.trim();
    //     this.selectedDistrict.id = key;
    //     this.fetchVillages();

    //     if (this.currentStep === 2){
    //         this.formData.identity_district = this.selectedDistrict.name;
    //         this.identityLocation.district = this.selectedDistrict.id;
    //     }
    //     if (this.currentStep === 3){
    //         this.formData.domicile_district = this.selectedDistrict.name;
    //         this.domicileLocation.district = this.selectedDistrict.id;
    //     }
    // },
    handleDistrictClick(value) {
      this.selectedDistrict.id = value
      const searchDistrict = this.districts.find(
        (district) => district.id === value,
      )
      this.fetchVillages(value)

      if (this.currentStep === 2) {
        this.formData.identity_district = searchDistrict.nama
        this.identityLocation.district = value
      }
      if (this.currentStep === 3) {
        this.formData.domicile_district = searchDistrict.nama
        this.domicileLocation.district = value
      }
    },
    // handleVillageClick({key, item}) {
    //     this.selectedVillage.name = item.$slots.default[0].text.trim();
    //     this.selectedVillage.id = key;

    //     if (this.currentStep === 2){
    //         this.formData.identity_village = this.selectedVillage.name;
    //         this.identityLocation.village = this.selectedVillage.id;
    //     }
    //     if (this.currentStep === 3){
    //         this.formData.domicile_village = this.selectedVillage.name;
    //         this.domicileLocation.village = this.selectedVillage.id;
    //     }
    // },
    handleVillageClick(value) {
      this.selectedVillage.id = value
      const searchVillage = this.villages.find(
        (village) => village.id === value,
      )

      if (this.currentStep === 2) {
        this.formData.identity_village = searchVillage.nama
        this.identityLocation.village = value
      }
      if (this.currentStep === 3) {
        this.formData.domicile_village = searchVillage.nama
        this.domicileLocation.village = value
      }
    },
    handleKunjunganClick({ key, item }) {
      this.formData.kunjungan = item.$slots.default[0].text.trim()
    },
    // handleIdentitasPasienClick({key, item}) {
    //     this.formData.patient_type = key;
    //     console.log('Patient Type:', this.formData.patient_type);
    // },
    handleIdentitasPasienClick(value) {
      this.formData.patient_type = value
      // console.log('Patient Type:', this.formData.patient_type);
    },
    handleGenderClick({ key, item }) {
      if (this.currentStep === 5) {
        this.formData.baby_gender = item.$slots.default[0].text.trim()
        // this.formData.baby_gender = key;
      } else {
        this.formData.gender = item.$slots.default[0].text.trim()
        // this.formData.gender = key;
        // console.log("gender", this.formData.gender);
      }
    },
    handleReligionClick({ key, item }) {
      this.formData.religion = item.$slots.default[0].text.trim()
    },
    handleEducationClick({ key, item }) {
      this.formData.education = item.$slots.default[0].text.trim()
    },
    handleJobClick({ key, item }) {
      this.formData.job = item.$slots.default[0].text.trim()
    },
    handleMartialStatusClick({ key, item }) {
      this.formData.martial_status = item.$slots.default[0].text.trim()
    },
    handlePaymentClick({ key, item }) {
      this.formData.payment_method = item.$slots.default[0].text.trim()
    },

    async getPhoneCode() {
      try {
        const response = await getPhoneCode()
        // console.log(response)
        this.phoneCode = response
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.fetchProvinces()
    // this.fetchRegencies();
    // this.fetchDistricts();
    // this.fetchVillages();
    // this.fetchPostalCodes();
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}

.custom-card {
  max-width: 750px;
  margin: 0 auto;
}

.steps {
  margin-bottom: 40px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 20px;
  padding-bottom: 20px;
}

.steps .ant-steps-item-title {
  text-align: center;
}

.custom-label-width label {
  margin-bottom: 0 !important;
}

.custom-dropdown {
  width: 100%;
  margin-top: 0;
}

.custom-dropdown-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.one-button-container {
  display: flex;
  justify-content: flex-end;
}
.two-button-container {
  display: flex;
  justify-content: space-between;
}
.button {
  border-radius: 20px;
  height: 40px;
  width: 120px;
  border: none;
  font-size: 16px;
}

.next-button {
  background-color: #428a94;
  color: white;
}
.next-button:hover {
  background-color: #2c6e77;
}
.next-button:active {
  background-color: #1f4f57;
}

.prev-button {
  background-color: white;
  color: #428a94;
  border: 1px solid #428a94;
}
.prev-button:hover {
  background-color: #f5f5f5;
}
.prev-button:active {
  background-color: #e5e5e5;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),(_vm.isLoading)?_c('a-spin'):_vm._e(),(!_vm.isLoading)?_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Kode Voucher"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {rules: [{ required: true, message: 'Kode Voucher harus diisi!' }]}]),expression:"['code', {rules: [{ required: true, message: 'Kode Voucher harus diisi!' }]}]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Masukkan Kode Voucher"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Jenis Test Laboratorium"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'testLabTypeId',
              { rules: [{ required: true, message: 'Jenis Test Laboratorium harus diisi!' }] },
            ]),expression:"[\n              'testLabTypeId',\n              { rules: [{ required: true, message: 'Jenis Test Laboratorium harus diisi!' }] },\n            ]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Jenis Test Laboratorium"}},_vm._l((_vm.testlabTypeList),function(testlabType){return _c('a-select-option',{key:testlabType.id,attrs:{"value":testlabType.id}},[_vm._v(" "+_vm._s(testlabType.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'status',
              { rules: [{ required: true, message: 'Status harus diisi!' }] },
            ]),expression:"[\n              'status',\n              { rules: [{ required: true, message: 'Status harus diisi!' }] },\n            ]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Status"}},_vm._l((_vm.statusList),function(status){return _c('a-select-option',{key:status.id,attrs:{"value":status.id}},[_vm._v(" "+_vm._s(status.name)+" ")])}),1)],1),_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit","disabled":_vm.submitting}},[_vm._v("Simpan Data")]),_c('button',{staticClass:"btn btn-danger px-5 m-1",attrs:{"disabled":_vm.submitting},on:{"click":(e)=>{e.preventDefault(); _vm.setModalVisible(true)}}},[_vm._v("Hapus Data")]),_c('button',{staticClass:"btn btn-secondary px-5",attrs:{"disabled":_vm.submitting},on:{"click":_vm.handleCancel}},[_vm._v("Kembali")])],1):_vm._e()],1)]),_c('a-modal',{attrs:{"title":"Konfirmasi","dialog-style":{ top: '20px' },"visible":_vm.modalVisible},on:{"ok":() => _vm.handleDelete(),"cancel":() => _vm.setModalVisible(false)}},[_vm._v(" Apakah Anda yakin akan menonaktifkan data voucher "+_vm._s(_vm.form.getFieldValue("code"))+"? ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Detail Voucher")])])
}]

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card",class:_vm.$style.container},[_vm._m(0),_c('span',{staticClass:"text-secondary mb-4"},[_vm._v("Don't worry, we'll send you a link to your email to change your password.")]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleResetPassword}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                { required: true, message: 'Please input your Email!' },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                { required: true, message: 'Please input your Email!' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Email"}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"size":"large","type":"primary","html-type":"submit"}},[_c('strong',[_vm._v("Reset Password")])])],1),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_c('i',{staticClass:"fe fe-arrow-left mr-1 align-middle"}),_vm._v(" Back to login page ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-dark font-size-24"},[_c('strong',[_vm._v("Forgot your password?")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Resume Rawat Jalan
        </div>
        <div class="card">
          <div class="card-body background-blue">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Waktu Pemeriksaan
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.checkup_time }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-xl-6">
              <div class="card">
                <div class="card-body">
                  <div class="h4 font-weight-bold text-color-green">
                    Resume Medis
                  </div>
                  <a-button
                    class="my-3"
                    style="
                      border-radius: 20px;
                      border: 1px solid #98a2b3;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="/resources/images/pdf.png"
                      alt=""
                      style="width: 16px; height: 16px; margin-right: 5px"
                    />
                    <p class="m-0" style="color: #98a2b3">PDF</p></a-button
                  >
                  <div class="h5 font-weight-bold">Catatan SOAP</div>

                  <div class="font-weight-bold SOAP-title-color">
                    Subjective
                  </div>
                  <div
                    v-html="
                      formatText(this.patientVisitData.DoctorNote.subjective)
                    "
                  ></div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">Objective</div>
                  <div
                    v-html="
                      formatText(this.patientVisitData.DoctorNote.objective)
                    "
                  ></div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">
                    Assessment
                  </div>
                  <div
                    v-html="
                      formatText(this.patientVisitData.DoctorNote.assessment)
                    "
                  ></div>
                  <div class="font-weight-bold">Diagnosis Primer:</div>
                  <div>
                    •
                    {{ this.patientVisitData.DoctorNote.Conditions[0].code }}
                    |
                    {{ this.patientVisitData.DoctorNote.Conditions[0].STR }}
                  </div>
                  <div class="font-weight-bold">Diagnosis Sekunder:</div>
                  <div
                    v-for="condition in this.patientVisitData.DoctorNote.Conditions.slice(
                      1,
                    )"
                    :key="condition.code"
                  >
                    • {{ condition.code }} | {{ condition.STR }}
                  </div>
                  <div
                    v-if="
                      this.patientVisitData.DoctorNote.Conditions.length === 1
                    "
                  >
                    -
                  </div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">Plan</div>
                  <div
                    v-html="formatText(this.patientVisitData.DoctorNote.plan)"
                  ></div>
                  <div class="font-weight-bold">Kode Tindakan:</div>
                  <div
                    v-for="action in this.patientVisitData.DoctorNote
                      .Procedures"
                    :key="action.code"
                  >
                    • {{ action.code }} | {{ action.STR }}
                  </div>
                  <div
                    v-if="
                      this.patientVisitData.DoctorNote.Procedures.length === 0
                    "
                  >
                    -
                  </div>
                  <br />
                  <a-divider />
                  <div class="h5 font-weight-bold">Anamnesis</div>

                  <div class="font-weight-bold">Keluhan Utama</div>
                  <div
                    v-for="(keluhan, index) in this.patientVisitData.Anamnesis
                      .ChiefComplaints"
                    :key="index"
                  >
                    <div>• {{ keluhan.keterangan }}</div>
                  </div>
                  <br />
                  <div class="font-weight-bold">Riwayat Penyakit</div>
                  <div>
                    <table class="table">
                      <thead>
                        <th
                          style="
                            width: 200px;
                            height: 32px;
                            padding-right: 15px;
                          "
                        >
                          Kategori
                        </th>
                        <th>Nama Penyakit</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(riwayat, index) in this.patientVisitData
                            .Anamnesis.IllnessHistories"
                          :key="index"
                        >
                          <td v-if="riwayat.type === 'personal'">
                            Riwayat Penyakit Pribadi
                          </td>
                          <td v-if="riwayat.type === 'family'">
                            Riwayat Penyakit Keluarga
                          </td>
                          <td>{{ riwayat.display }}</td>
                        </tr>
                        <tr
                          v-if="
                            this.patientVisitData.Anamnesis.IllnessHistories
                              .length === 0
                          "
                        >
                          <td>Tidak ada data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div class="font-weight-bold">Riwayat Alergi</div>
                  <div>
                    <table class="table">
                      <thead>
                        <th
                          style="
                            width: 200px;
                            height: 32px;
                            padding-right: 15px;
                          "
                        >
                          Jenis Alergi
                        </th>
                        <th>Nama Alergen</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="allergy in this.patientVisitData.Anamnesis
                            .Allergies"
                          :key="allergy.id"
                        >
                          <!-- <td>{{ allergy.type }}</td> -->
                          <td v-if="allergy.type === 1">Medication</td>
                          <td v-if="allergy.type === 2">Food</td>
                          <td v-if="allergy.type === 3">Environment</td>
                          <td v-if="allergy.type === 4">Biologic</td>
                          <td>{{ allergy.display }}</td>
                        </tr>
                        <tr
                          v-if="
                            this.patientVisitData.Anamnesis.Allergies.length ===
                            0
                          "
                        >
                          <td>Tidak ada data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div class="font-weight-bold">Riwayat Pengobatan</div>
                  <div>
                    <table class="table">
                      <thead>
                        <th style="height: 32px; padding-right: 15px">
                          Nama Obat
                        </th>
                        <th>Dosis dan Frekuensi</th>
                        <th>Status</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="medication in this.patientVisitData.Anamnesis
                            .MedicationStatements"
                          :key="medication.id"
                        >
                          <td>{{ medication.name }}</td>
                          <td>{{ medication.dosage }}</td>
                          <td>
                            {{
                              medicineStatus.find(
                                (item) => item.value === medication.status,
                              ).label
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            this.patientVisitData.Anamnesis.MedicationStatements
                              .length === 0
                          "
                        >
                          <td>Tidak ada data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a-divider />
                  <div class="h5 font-weight-bold">Penunjang</div>
                  <div v-if="this.patientVisitDocuments.length === 0">
                    Pasien ini belum memiliki dokumen penunjang.
                  </div>
                  <div
                    v-for="file in this.patientVisitDocuments"
                    :key="file.id"
                  >
                    <div>
                      Diunggah oleh {{ file.created_by_name }} pada
                      {{ formatDateTimeFull(file.VisitDocument.createdAt) }}
                    </div>
                    <div v-if="file.media_mime_type !== 'application/pdf'">
                      <div class="image-container">
                        <object
                          :data="file.uri"
                          :type="file.media_mime_type"
                          width="400px"
                          min-height="100%"
                        ></object>
                        <button
                          class="zoom-button"
                          @click="openViewer(file.uri, file)"
                        >
                          <img
                            src="resources/images/zoom-icon.svg"
                            alt="Zoom In"
                            width="20px"
                            height="20px"
                          />
                        </button>
                      </div>
                    </div>
                    <div v-else style="min-width: 50vw">
                      <object
                        :data="file.uri"
                        :type="file.media_mime_type"
                        width="100%"
                        height="700px"
                      ></object>
                    </div>
                    <a :href="file.uri" target="_blank">{{ file.name }}</a>
                    <br /><br />
                  </div>
                  <a-divider />
                  <div class="h5 font-weight-bold">Obat</div>

                  <div class="font-weight-bold SOAP-title-color">Resep</div>
                  <div
                    v-for="(medication, index) in this.patientVisitData
                      .DoctorNote.MedicationRequests"
                    :key="index"
                  >
                    • {{ medication.name }} | {{ medication.quantity }} ({{
                      medication.dosage
                    }}
                    x {{ medication.frequency }},
                    {{ medication.use_morning === true ? 'Pagi' : '' }}
                    {{ medication.use_noon === true ? 'Siang' : '' }}
                    {{ medication.use_night === true ? 'Malam' : '' }})
                  </div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">
                    Catatan Resep
                  </div>
                  <div
                    v-if="this.patientVisitData.DoctorNote.medication_note"
                    v-html="
                      formatText(
                        this.patientVisitData.DoctorNote.medication_note,
                      )
                    "
                  ></div>
                  <div v-else>Tidak Ada</div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="card">
                <div class="card-body">
                  <div>
                    <p class="font-weight-bold">
                      Diagnosis Primer<span class="text-danger">*</span>
                    </p>

                    <a-form-item
                      v-for="(input, index) in primary_condition"
                      :key="index"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="height: 2.5rem"
                      >
                        <div class="mr-3" style="width: 90%">
                          <a-select
                            show-search
                            @search="searchICD10"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model="input.code"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            style="min-width: 15rem"
                          >
                            <a-select-option
                              v-for="(item, idx) in ICD10List"
                              :key="idx"
                              :value="item.skri + ' | ' + item.STR"
                            >
                              {{ item.skri }} | {{ item.STR }}</a-select-option
                            >
                          </a-select>
                        </div>
                        <div
                          class="d-flex align-items-center justify-content-between"
                          style="width: 50px"
                        >
                          <a @click="removePrimaryInput(index)" class="h4">x</a>
                        </div>
                      </div>
                    </a-form-item>

                    <div
                      @click="addPrimaryInput"
                      v-if="primary_condition.length === 0"
                      class="mt-2 h6 mb-3"
                      style="color: #428a94"
                    >
                      <a>+ Tambah Kode ICD-10</a>
                    </div>
                  </div>
                  <div v-if="this.primary_recommendations.length > 0">
                    <div class="font-weight-bold">
                      Rekomendasi Diagnosis ICD-10
                    </div>
                    <div>
                      Tekan kode ICD-10 untuk menambahkan kode ke diagnosis
                    </div>
                    <div class="recommendation-button-container">
                      <div
                        v-for="(text, index) in primary_recommendations"
                        :key="index"
                      >
                        <a-button
                          class="mb-2"
                          style="border: 1px solid #428a94"
                          shape="round"
                          @click="addPrimaryConditions(text)"
                          >{{ text.code }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <a-divider />

                  <!-- Sekunder -->
                  <div class="mt-4">
                    <p class="font-weight-bold">
                      Diagnosis Sekunder<span class="text-danger">*</span>
                    </p>

                    <a-form-item
                      v-for="(input, index) in secondary_condition"
                      :key="index"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="height: 2.5rem"
                      >
                        <div class="mr-3" style="width: 90%">
                          <a-select
                            show-search
                            @search="searchICD10"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model="input.code"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            style="min-width: 15rem"
                          >
                            <a-select-option
                              v-for="item in ICD10List"
                              :key="item.skri"
                              :value="item.skri + ' | ' + item.STR"
                            >
                              {{ item.skri }} | {{ item.STR }}</a-select-option
                            >
                          </a-select>
                        </div>
                        <div
                          class="d-flex align-items-center justify-content-between"
                          style="width: 50px"
                        >
                          <a @click="removeSecondaryInput(index)" class="h4"
                            >x</a
                          >
                        </div>
                      </div>
                    </a-form-item>

                    <div
                      @click="addSecondaryInput"
                      class="mt-2 h6 mb-3"
                      style="color: #428a94"
                    >
                      <a>+ Tambah Kode ICD-10</a>
                    </div>
                  </div>
                  <div v-if="this.secondary_recommendations.length > 0">
                    <div class="font-weight-bold">Rekomendasi ICD-10</div>
                    <div>
                      Tekan kode ICD-10 untuk menambahkan kode ke diagnosis
                    </div>
                    <div class="recommendation-button-container">
                      <div
                        v-for="(text, index) in secondary_recommendations"
                        :key="index"
                      >
                        <a-button
                          class="mb-2"
                          style="border: 1px solid #428a94"
                          shape="round"
                          @click="addSecondaryConditions(text)"
                          v-if="text.status !== 'delete'"
                          >{{ text.code }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <a-divider />
                  <!-- Tindakan -->
                  <div class="mt-4">
                    <p class="font-weight-bold">Tindakan</p>
                    <a-form-item
                      v-for="(input, index) in tindakan_condition"
                      :key="index"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="height: 2.5rem"
                      >
                        <div class="mr-3" style="width: 90%">
                          <a-select
                            show-search
                            @search="searchICD9CM"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model="input.code"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            style="min-width: 15rem"
                          >
                            <a-select-option
                              v-for="item in ICD9CMList"
                              :key="item.skri"
                              :value="item.skri + ' | ' + item.STR"
                            >
                              {{ item.CODE }} | {{ item.STR }}</a-select-option
                            >
                          </a-select>
                        </div>
                        <div
                          class="d-flex align-items-center justify-content-between"
                          style="width: 50px"
                        >
                          <a @click="removeTindakanInput(index)" class="h4"
                            >x</a
                          >
                        </div>
                      </div>
                    </a-form-item>

                    <div
                      @click="addTindakanInput"
                      class="mt-2 h6 mb-3"
                      style="color: #428a94"
                    >
                      <a>+ Tambah Kode ICD-9</a>
                    </div>
                  </div>
                  <div v-if="this.tindakan_recommendations.length > 0">
                    <div class="font-weight-bold">Rekomendasi ICD-9</div>
                    <div>
                      Tekan kode ICD-9 untuk menambahkan kode ke diagnosis
                    </div>
                    <div class="recommendation-button-container">
                      <div
                        v-for="(text, index) in tindakan_recommendations"
                        :key="index"
                      >
                        <a-button
                          class="mb-2"
                          style="border: 1px solid #428a94"
                          shape="round"
                          @click="addTindakanConditions(text)"
                          >{{ text.code }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="card p-4"
                      style="
                        background-color: #f9fdff;
                        border: none;
                        border-radius: 20px;
                      "
                    >
                      <p class="font-weight-bold">Rekomendasi Perbaikan</p>
                      <p class="font-weight-bold">Diagnosis:</p>
                      <div v-if="this.primary_recommendations.length">
                        Primer: <br />
                        <ul>
                          <li
                            v-for="(el, idx) in this.primary_recommendations"
                            :key="idx"
                          >
                            {{ el.code + ' (' + el.reason + ')' }}.
                          </li>
                        </ul>
                        Sekunder: <br />
                        <ul>
                          <li
                            v-for="(el, idx) in this.secondary_recommendations"
                            :key="idx"
                          >
                            {{ el.code + ' (' + el.reason + ')' }}.
                          </li>
                        </ul>
                      </div>
                      <p class="font-weight-bold m-0 mb-2">
                        Tindakan/Prosedur:
                      </p>
                      <ul>
                        <li
                          v-for="(el, idx) in this.tindakan_recommendations"
                          :key="idx"
                        >
                          {{ el.code + ' (' + el.reason + ')' }}.
                        </li>
                      </ul>
                      <a-button
                        class="my-3"
                        style="
                          border-radius: 20px;
                          border: 1px solid #428a94;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 221px;
                          height: 33px;
                        "
                        @click="generateNewRecommendations"
                      >
                        <img
                          src="/resources/images/spark.png"
                          alt=""
                          style="width: 16px; height: 16px; margin-right: 5px"
                        />
                        <p class="m-0" style="color: #428a94">
                          Buat Ulang Rekomendasi
                        </p></a-button
                      >
                    </div>
                    <a-button
                      class="my-3"
                      style="
                        border-radius: 20px;
                        border: 1px solid #428a94;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 221px;
                        height: 33px;
                        background-color: #428a94;
                      "
                      @click="submitData"
                    >
                      <img
                        src="/resources/images/save.png"
                        alt=""
                        style="width: 16px; height: 16px; margin-right: 5px"
                      />
                      <p class="m-0" style="color: white">
                        Simpan Hasil Verifikasi
                      </p></a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPatientVisit,
  getDokumenPenunjang,
  saveCoderNote,
} from '@/services/axios/api/kunjungan'
import {
  searchICD10Code,
  searchICD9CMCode,
  generateICD10Code,
  generateICD9CMCode,
} from '@/services/axios/api/icdCodes'
import { getUserProfileFromId } from '@/services/axios/api/auth'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'

export default {
  data() {
    return {
      patientVisitData: {},
      patientVisitDocuments: [],
      visit_id: '',
      medicineStatus: [
        { label: 'Active', value: 'active' },
        { label: 'Completed', value: 'completed' },
        { label: 'Entered in Error', value: 'entered-in-error' },
        { label: 'Intended', value: 'intended' },
        { label: 'Stopped', value: 'stopped' },
        { label: 'Draft', value: 'draft' },
        { label: 'On Hold', value: 'on-hold' },
        { label: 'Unknown', value: 'unknown' },
        { label: 'Not Taken', value: 'not-taken' },
      ],
      conditions_autoGenerated: [],
      primary_condition: [],
      old_primary_condition: [],
      primary_recommendations: [],
      secondary_recommendations: [],
      secondary_condition: [],
      old_secondary_condition: [],
      ICD10List: [],
      tindakan_recommendations: [],
      tindakan_condition: [],
      old_tindakan_condition: [],
      ICD9CMList: [],
      codeLogList: [],
    }
  },
  created() {
    this.visit_id = this.$route.params.id
    this.fetchData()
  },

  methods: {
    async fetchData() {
      await this.getPatientVisitData()

      console.log('bawahini')
      console.log(this.patientVisitData.DoctorNote.Conditions)
      if (this.patientVisitData.DoctorNote.Conditions.length) {
        const primaryCondition =
          this.patientVisitData.DoctorNote.Conditions.filter(
            (e) => e.condition_type === 'primary',
          )
        const secondaryCondition =
          this.patientVisitData.DoctorNote.Conditions.filter(
            (e) => e.condition_type === 'secondary',
          )
        const codePrimary = `${primaryCondition[0].code} | ${primaryCondition[0].STR}`
        this.primary_condition.push({
          ...primaryCondition[0],
          code: codePrimary,
        })
        this.old_primary_condition.push({
          ...primaryCondition[0],
          code: codePrimary,
        })
        secondaryCondition.forEach((e) => {
          const codeSecondary = `${e.code} | ${e.STR}`
          this.secondary_condition.push({
            ...e,
            code: codeSecondary,
          })
          this.old_secondary_condition.push({
            ...e,
            code: codeSecondary,
          })
        })
      }
      if (this.patientVisitData.DoctorNote.Procedures.length) {
        this.tindakan_condition =
          this.patientVisitData.DoctorNote.Procedures.map((e) => {
            const codeTindakan = `${e.code} | ${e.STR}`
            return {
              ...e,
              code: codeTindakan,
            }
          })
        this.old_tindakan_condition =
          this.patientVisitData.DoctorNote.Procedures.map((e) => {
            const codeTindakan = `${e.code} | ${e.STR}`
            return {
              ...e,
              code: codeTindakan,
            }
          })
      }
      const primaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === 'primary',
      )
      const secondaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === 'secondary',
      )
      await this.generateICD10(
        this.patientVisitData.DoctorNote.assessment,
        primaryCode,
        secondaryCode,
      )
      await this.generateICD9CM(this.patientVisitData.DoctorNote.plan)
    },
    addSecondaryInput() {
      this.secondary_condition.push({ code: '' })
      // console.log(this.secondary_conditions, 'secondary_conditions')
    },
    addTindakanInput() {
      this.tindakan_condition.push({ code: '' })
      // console.log(this.secondary_conditions, 'secondary_conditions')
    },

    addPrimaryConditions(text) {
      const newObj = {
        ...text,
      }
      console.log(text, 'iniliat')
      const newCondition = [newObj]
      this.primary_condition = newCondition
    },
    addSecondaryConditions(text) {
      const newObj = {
        ...text,
      }
      this.secondary_condition.push(newObj)
    },
    addTindakanConditions(text) {
      const newObj = {
        ...text,
        status: 'preparation',
      }
      this.tindakan_condition.push(newObj)
    },
    addPrimaryInput() {
      this.primary_condition.push({ code: '' })
      // console.log(this.primary_condition, 'primary_condition')
    },
    setTab() {
      const role = window.localStorage.getItem('role')
      if (role === 'perawat') {
        return '1'
      } else if (role === 'dokter') {
        if (this.patientVisitData.status === 'Selesai') {
          return '2'
        } else {
          return '1'
        }
      } else {
        return '1'
      }
    },
    openViewer(fileUri, file) {
      const imageElement = document.createElement('img')
      imageElement.src = fileUri
      imageElement.style.display = 'none'
      document.body.appendChild(imageElement)
      console.log(imageElement, 'image element')
      const viewer = new Viewer(imageElement, {
        inline: false,
        navbar: false,
        title: [
          1,
          (imageData) =>
            `${file.name} (${imageData.naturalWidth} x ${imageData.naturalHeight})`,
        ],
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          play: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        viewed() {
          viewer.zoomTo(0.5)
        },
        hidden() {
          document.body.removeChild(imageElement)
        },
      })
      viewer.show()
    },
    formatText(text) {
      return text.replace(/\n/g, '<br>')
    },
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const date = new Date(dateString)
      return date.toLocaleString('id-ID', options)
    },
    formatDateTime(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
    },
    formatDateTimeFull(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, '0')
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ]
      const month = monthNames[date.getMonth()]
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error('Error fetching user name:', error)
        return 'Unknown'
      }
    },
    async getHistoryDocumentImageUrl(file, visitId) {
      const response = await getDokumenPenunjang(visitId, file.id)
      const url = URL.createObjectURL(response.data)
      return url
    },
    async getPatientVisitData() {
      const response = await getPatientVisit(this.visit_id)
      this.patientVisitData = response.data.visit

      if (
        this.patientVisitData.Anamnesis === null ||
        this.patientVisitData.Anamnesis.length === 0
      ) {
        this.$notification.error({
          message: 'Data Tidak Ditemukan!',
          description: 'Data pemeriksaan belum diisi!',
          duration: 5,
        })
        this.$router.go(-1)
        return
      }

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAge(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === 'Male'
            ? 'Laki-laki'
            : 'Perempuan',
        payment_method:
          this.patientVisitData.Patient.payment_method === 'JKN'
            ? 'JKN (BPJS Kesehatan)'
            : this.patientVisitData.Patient.payment_method,
        checkup_time: this.formatDateTime(this.patientVisitData.checkup_time),
        checkup_date: this.formatDate(this.patientVisitData.checkup_time),
        nurse_name: await this.getUserName(
          this.patientVisitData.attending_nurse_id,
        ),
      }
      // this.patientVisitDocuments = this.patientVisitData.documents
      for (const file in response.data.documents) {
        // this.patientVisitDocuments.push({
        //     name: this.patientVisitData.documents[file].name,
        //     url: this.patientVisitData.documents[file].url,
        // })
        response.data.documents[file].created_by_name = await this.getUserName(
          response.data.documents[file].VisitDocument.created_by,
        )
        response.data.documents[file].uri =
          await this.getHistoryDocumentImageUrl(
            response.data.documents[file],
            this.patientVisitData.id,
          )
      }
      this.patientVisitDocuments = response.data.documents
      // console.log(this.patientVisitData, 'data kunjungan pasien')
      // console.log(this.patientVisitDocuments, 'dokumen kunjungan pasien')
    },
    async generateICD10(payloadData, primaryCode, secondaryCode) {
      let prompt = `\n Primary Diagnosis : `
      if (primaryCode.length) {
        primaryCode.forEach((e) => {
          prompt += `\n - ${e.code}`
        })
      }
      if (secondaryCode.length) {
        prompt += `\nSecondary Diagnosis :`
        secondaryCode.forEach((e) => {
          prompt += `\n - ${e.code} | ${e.STR}`
        })
      }
      this.ICD10Loading = true
      const assessment = payloadData
      const payload = {
        text: assessment + prompt,
      }
      console.log(assessment + prompt)
      const res = await generateICD10Code(payload)

      const data = res.data.codes
      if (data.length > 0) {
        const formattedData = data.map((item) => {
          return {
            ...item,
            code: item.skri + ' | ' + item.STR,
          }
        })
        // this.primary_condition = []
        // this.primary_condition_autoGenerated = []
        // this.primary_condition_autoGenerated = formattedData.slice(0, 1)

        if (formattedData.length > 0) {
          this.conditions_autoGenerated = formattedData
          console.log(formattedData, 'tesini')
          this.primary_recommendations = []
          this.secondary_recommendations = []
          formattedData.forEach((e) => {
            if (e.type === 'Primary') {
              this.primary_recommendations.push(e)
            } else {
              this.secondary_recommendations.push(e)
            }
          })
          console.log(this.conditions_autoGenerated, 'niya')
        }
        // console.log(this.primary_condition_autoGenerated, 'primary_condition_autoGenerated')
        // console.log(this.secondary_conditions_autoGenerated, 'secondary_conditions_autoGenerated')
      } else {
        this.$notification.warning({
          message: 'Peringatan',
          description: 'Tidak ada kode ICD-10 yang dihasilkan',
          duration: 5,
        })
      }
      this.ICD10Loading = false
    },
    async getICD10Codes(search) {
      const ICD10response = await searchICD10Code(search)
      this.ICD10List = ICD10response.data.codes
      // console.log(this.ICD10List, 'ICD10 List')
    },
    async searchICD10(query) {
      if (query) {
        try {
          // console.log('Searching ICD10 codes:', query)
          const response = await searchICD10Code(query)
          this.ICD10List = response.data.codes
          // console.log('ICD10 codes:', this.ICD10List)
        } catch (error) {
          console.error('Error fetching ICD10 codes:', error)
          this.ICD10List = []
        }
      } else {
        this.ICD10List = []
      }
    },
    async generateICD9CM(payloadData) {
      console.log(payloadData, 'iniplan')
      this.ICD9CMLoading = true
      try {
        const payload = {
          text: payloadData,
        }
        const res = await generateICD9CMCode(payload)
        const data = res.data.codes
        if (data.length > 0) {
          const formattedData = data.map((item) => ({
            ...item,
            code: item.CODE + ' | ' + item.STR,
          }))
          console.log(formattedData, 'cek ini')
          this.tindakan_recommendations = formattedData
        } else {
          this.$notification.warning({
            message: 'Peringatan',
            description: 'Tidak ada kode ICD-9-CM yang dihasilkan',
            duration: 5,
          })
        }
      } catch (error) {
        console.error('Error generating ICD-9-CM:', error)
        this.$notification.error({
          message: 'Gagal',
          description: 'Terjadi kesalahan saat menghasilkan kode ICD-9-CM',
          duration: 5,
        })
      }
      this.ICD9CMLoading = false
    },
    async getICD9CMCodes(search) {
      const ICD9CMresponse = await searchICD9CMCode(search)
      this.ICD9CMList = ICD9CMresponse.data.codes
      // console.log(this.ICD9CMList, 'ICD9CM List')
    },
    async searchICD9CM(query) {
      if (query) {
        try {
          // console.log('Searching ICD9CM codes:', query)
          const response = await searchICD9CMCode(query)
          this.ICD9CMList = response.data.codes
          console.log('ICD9CM codes:', this.ICD9CMList)
        } catch (error) {
          console.error('Error fetching ICD9CM codes:', error)
          this.ICD9CMList = []
        }
      } else {
        this.ICD9CMList = []
      }
    },

    async generateNewRecommendations() {
      const primaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === 'primary',
      )
      const secondaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === 'secondary',
      )
      await this.generateICD10(
        this.patientVisitData.DoctorNote.assessment,
        primaryCode,
        secondaryCode,
      )
      await this.generateICD9CM(this.patientVisitData.DoctorNote.plan)
    },

    removePrimaryInput(index) {
      this.primary_condition.splice(index, 1)
    },
    removeSecondaryInput(index) {
      this.secondary_condition.splice(index, 1)
    },
    removeTindakanInput(index) {
      this.tindakan_condition.splice(index, 1)
    },
    async submitData() {
      const obj = {
        visit_id: this.visit_id,
        primary_condition: this.primary_condition,
        old_primary_condition: this.old_primary_condition,
        secondary_conditions: this.secondary_condition,
        old_secondary_conditions: this.old_secondary_condition,
        procedures: this.tindakan_condition,
        old_procedures: this.old_tindakan_condition,
      }
      await saveCoderNote(obj)
      this.$router.push('/')
      // console.log(this.primary_condition, 'submit')
      // console.log(this.secondary_condition, 'submit')
      // console.log(this.tindakan_condition, 'submit')
    },
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}
.background-blue {
  background-color: #f6feff;
}
.table thead {
  border-bottom: 1px solid #e5e5e5;
}
.table th,
.table td {
  border: none;
  padding: 8px;
}
.SOAP-title-color {
  color: #428a94;
}
.image-container {
  position: relative;
  display: inline-block;
  margin: 0;
}
.zoom-button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 999px;
}
.zoom-button:hover {
  background-color: rgba(255, 255, 255, 1);
}
</style>

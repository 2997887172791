var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"card overflow-hidden"},[_c('div',{class:_vm.$style.new},[_vm._v("New")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('a',{class:[_vm.$style.favorite, _vm.favorite ? 'text-dark' : 'text-gray-3'],attrs:{"href":"#"},on:{"click":_vm.toggleFavorite}},[_c('i',{staticClass:"fe fe-heart font-size-21"})]),_c('div',{staticClass:"height-250 mb-3",class:_vm.$style.image},[_c('img',{attrs:{"src":_vm.images[_vm.activeImgIndex],"alt":"Product"}})]),_c('div',{staticClass:"d-flex flex-wrap mb-3"},_vm._l((_vm.images),function(image,index){return _c('a',{key:index,staticClass:"width-100 height-100 border mr-2 mb-2",class:[index === _vm.activeImgIndex ? 'border-primary' : '', _vm.$style.thumb],attrs:{"href":"#"},on:{"click":function($event){return _vm.setImage(index)}}},[_c('img',{attrs:{"src":image,"alt":"Product"}})])}),0)]),_c('div',{staticClass:"col-lg-8"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"mb-4 width-300"},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"defaultValue":"Red"}},[_c('a-select-option',{attrs:{"value":"red"}},[_vm._v("Red")]),_c('a-select-option',{attrs:{"value":"black"}},[_vm._v("Black")]),_c('a-select-option',{attrs:{"value":"cyan"}},[_vm._v("Cyan")]),_c('a-select-option',{attrs:{"value":"blue"}},[_vm._v("Blue")])],1)],1),_vm._m(3),_c('a-tabs',{staticClass:"kit-tabs-bordered",attrs:{"defaultActiveKey":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"Information"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"Description"}})],1),_vm._m(4)],1)])])]),_vm._m(5),_c('div',{staticClass:"row"},_vm._l((_vm.data.products),function(product,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('cui-general-16',{attrs:{"isNew":product.isNew,"isFavorite":product.isFavorite,"image":product.image,"name":product.name,"price":product.price,"oldPrice":product.oldPrice}})],1)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cui__utils__heading"},[_c('strong',[_vm._v("Ecommerce: Product Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-size-24 font-weight-bold text-dark mb-2"},[_vm._v(" $199.28 "),_c('del',{staticClass:"align-text-top font-size-14"},[_vm._v("$299.28")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-3 mb-4 border-bottom"},[_c('a',{staticClass:"text-blue font-size-18",attrs:{"href":"javascript:void(0);"}},[_vm._v(" TP-Link AC1750 Smart WiFi Router - Dual "),_c('br'),_vm._v("Band Gigabit Wireless Internet Routers for "),_c('br'),_vm._v("Home ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"width-200 btn btn-success btn-with-addon mr-auto mb-3 text-nowrap d-none d-md-block",attrs:{"href":"javascript: void(0);"}},[_c('span',{staticClass:"btn-addon"},[_c('i',{staticClass:"btn-addon-icon fe fe-plus-circle"})]),_vm._v(" Add To Card ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body px-0"},[_c('p',[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ")]),_c('p',[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cui__utils__heading"},[_c('h5',[_vm._v("Related Products")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <div class="card mt-5" :class="$style.container">
      <div>
        <img
          src="resources/images/medisync-withtext.svg"
          alt="Medisync"
          height="52"
          class="mb-4"
        />
      </div>
      <div v-if="steps == 1">
        <div class="mb-4">
          <div class="h1" :class="$style.welcomeText">Selamat datang!</div>
          <div class="h6">Buat akun Sistem Rekam Medis Elektronik</div>
        </div>
        <button
          size="large"
          class="text-center w-100 mb-4 d-flex align-items-center justify-content-center"
          :class="$style.googleButton"
          @click="handleGoogleLogin"
        >
          <img
            src="resources/images/google-icon.svg"
            alt="Google"
            height="26"
            class="mr-2"
          />
          <div class="font-size-18 justify-content-center">
            Daftar dengan Google
          </div>
          <div ref="googleLoginBtn" hidden />
        </button>

        <div class="d-flex align-items-center justify-content-center my-3">
          <div :class="$style.line"></div>
          <span class="mx-2">atau daftar dengan</span>
          <div :class="$style.line"></div>
        </div>
      </div>
      <div v-if="steps != 1">
        <h1 :class="[$style.welcomeText, $style.registerHeader]">
          Pendaftaran Akun
        </h1>
        <div
          :class="$style.containerStepsRegister"
          v-if="!settings.isMobileView"
        >
          <div
            v-for="(item, index) in mySteps"
            :key="index"
            :class="[
              $style.containerItemStep,
              {
                [$style.containerItemStepDone]: index + 1 < steps,
              },
            ]"
          >
            <div
              :class="[
                $style.circleStep,
                {
                  [$style.activeCircle]: steps == index + 1,
                  [$style.done]: index + 1 < steps,
                },
              ]"
            >
              <i
                class="fa fa-check"
                style="color: white; font-size: 8px"
                v-if="index + 1 < steps"
              ></i>
              <div
                v-if="steps == index + 1"
                :class="$style.dotActiveCircle"
              ></div>
            </div>
            <p>
              {{ item }}
            </p>
          </div>
        </div>
      </div>

      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <div v-if="steps == 1">
          <a-form-item>
            <div class="h6 font-weight-bold">
              Email<span class="text-danger">*</span>
            </div>
            <a-input
              size="default"
              placeholder="Alamat email"
              v-decorator="[
                'email',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan email Anda!' },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-button
            size="large"
            class="text-center w-100"
            type="primary"
            html-type="button"
            shape="round"
            :class="$style.submitButton"
            @click="handleNextSteps"
            :loading="isLoading"
            :disabled="!form.getFieldValue('email')"
          >
            <strong class="h5">Daftar</strong>
          </a-button>

          <div class="text-left pt-2 mt-3">
            <span class="mr-2">Sudah punya akun?</span>
            <router-link to="/auth/login" class="kit__utils__link font-size-16"
              >Masuk sekarang</router-link
            >
          </div>
        </div>
        <div v-if="steps == 2">
          <div>
            <p style="color: #101828">
              Masukkan kode verifikasi yang telah dikirim ke email
              <span style="color: #101828; font-weight: 600">{{
                modelRegis.email
              }}</span
              >. Silakan lihat di kotak masuk atau spam.
            </p>
            <SimpleOtpInput
              v-model="lazyCode"
              :inputClasses="$style.otpInputStyle"
              :class="[$style.containerOtp, $style['simple-otp-input']]"
              :pasteDelayMs="150"
            />
            <p
              style="margin: 15px 0; color: #101828"
              @click="
                () => {
                  if (isExpiredTime) {
                    handleTrySendCode(modelRegis.email)
                  }
                }
              "
            >
              Belum mendapatkan kode?
              <span
                style="color: #428a94"
                :class="[{ [$style.textResendCode]: isExpiredTime }]"
                >Kirim ulang kode
                <countdown
                  :time="120 * 1000"
                  :transform="transformCountDown"
                  @end="handleEndCount"
                  v-if="!isExpiredTime"
                >
                  <template slot-scope="props"
                    >({{ props.minutes }}: {{ props.seconds }})</template
                  >
                </countdown></span
              >
            </p>
          </div>
          <div class="row">
            <!-- <a-button
              size="large"
              class="text-center mb-sm-0 mb-2 mr-0 w-100 col-sm-6 col-12"
              type="primary"
              shape="round"
              @click="handleBackSteps"
              :class="$style.submitButton"
              :loading="isLoading"
            >
              <strong class="h5">Kembali</strong>
            </a-button> -->
            <a-button
              size="large"
              class="text-center ml-0 w-100 col-12"
              type="primary"
              shape="round"
              :class="$style.submitButton"
              @click="handleNextSteps"
              :loading="isLoading"
            >
              <strong class="h5">Lanjut</strong>
            </a-button>
          </div>

          <div class="text-left pt-2 mt-3">
            <span class="mr-2">Sudah punya akun?</span>
            <router-link to="/auth/login" class="kit__utils__link font-size-16"
              >Masuk sekarang</router-link
            >
          </div>
        </div>
        <div v-if="steps === 3">
          <a-form-item>
            <div class="h6 font-weight-bold">
              Kata Sandi<span class="text-danger">*</span>
            </div>
            <a-input-password
              size="default"
              placeholder="Kata sandi"
              v-decorator="[
                'password',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan kata sandi Anda!' },
                    { min: 8, message: 'Kata sandi minimal 8 karakter!' },
                  ],
                },
              ]"
              autocomplete="new-password"
              style="width: 100%"
              @change="(e) => handleChangePassword(e)"
            />
            <!-- <p class="small" style="height: 8px">
              Kata sandi minimal 8 karakter
            </p> -->
          </a-form-item>
          <div style="margin: 10px 0">
            <div style="display: flex; align-items: center">
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/passed.png"
                alt="checklist pass"
                v-if="modelRegis.password.length >= 8"
              />
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/unpass.png"
                alt="x pass"
                v-else
              />
              Minimal 8 karakter
            </div>
            <div style="display: flex; align-items: center">
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/passed.png"
                alt="checklist pass"
                v-if="passwordCheck.capital"
              />
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/unpass.png"
                alt="x pass"
                v-else
              />
              Menggunakan huruf Kapital
            </div>
            <div style="display: flex; align-items: center">
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/passed.png"
                alt="checklist pass"
                v-if="passwordCheck.number"
              />
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/unpass.png"
                alt="x pass"
                v-else
              />
              Menggunakan angka
            </div>
            <div style="display: flex; align-items: center">
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/passed.png"
                alt="checklist pass"
                v-if="passwordCheck.symbol"
              />
              <img
                style="margin-right: 10px; width: 16px; height: 16px"
                src="./icon/unpass.png"
                alt="x pass"
                v-else
              />
              Menggunakan karakter spesial (!@#$%^&*_-)
            </div>
          </div>
          <a-form-item>
            <div class="h6 font-weight-bold">
              Konfirmasi Kata Sandi<span class="text-danger">*</span>
            </div>
            <a-input-password
              size="default"
              placeholder="Konfirmasi kata sandi"
              v-decorator="[
                'confirmPassword',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Konfirmasi kata sandi Anda!',
                    },
                    { validator: this.checkPassword },
                  ],
                },
              ]"
              autocomplete="new-password"
              @change="(e) => handleChangeConfirmPassword(e)"
              style="width: 100%"
            />
          </a-form-item>

          <a-button
            size="large"
            class="text-center w-100"
            type="primary"
            shape="round"
            :class="$style.submitButton"
            :loading="isLoading"
            :disabled="!disabledPassword"
            @click="handleNextSteps"
          >
            <strong class="h5">Selanjutnya</strong>
          </a-button>

          <div class="text-left pt-2 mt-3">
            <span class="mr-2">Sudah punya akun?</span>
            <router-link to="/auth/login" class="kit__utils__link font-size-16"
              >Masuk sekarang</router-link
            >
          </div>
        </div>
        <div v-if="steps === 4">
          <a-form-item>
            <div class="h6 font-weight-bold">
              Nama Lengkap<span class="text-danger">*</span>
            </div>
            <a-input
              size="default"
              placeholder="Nama lengkap"
              v-decorator="[
                'name',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan nama lengkap Anda!' },
                  ],
                },
              ]"
            />
          </a-form-item>

          <!-- <a-form-item>
          <div class="h6 font-weight-bold">Tipe Fasilitas Pelayanan Kesehatan<span class="text-danger">*</span></div>
          <a-select
            size="default"
            placeholder="Pilih tipe fayankes"
            @change="handleFayankesTypeClick"
            v-decorator="[
                'tipeFasyankes',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Pilih tipe fasilitas pelayanan kesehatan Anda!' },
                  ],
                },
              ]"
          >
            <a-select-option value="" disabled selected>Pilih tipe fayankes</a-select-option>
            <a-select-option v-for="fayankes in fayankesList" :key="fayankes" :value="fayankes"> {{ fayankes }} </a-select-option>
          </a-select>
        </a-form-item> -->

          <!-- <a-form-item>
          <div class="h6 font-weight-bold">Nama Fasilitas Pelayanan Kesehatan<span class="text-danger">*</span></div>
          <a-select
            show-search
            placeholder="Nama fasyankes"
            option-filter-prop="children"
            :filterOption="(input, option) => option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0"
            v-decorator="[
                'medical_facility_id',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Masukkan nama fasilitas pelayanan kesehatan Anda!' },
                  ],
                },
              ]"
          >
            <a-select-option value="" disabled selected>Nama fasyankes</a-select-option>
            <a-select-option v-for="item in medicalFacility" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item> -->

          <a-form-item>
            <div class="h6 font-weight-bold">
              Role Akun<span class="text-danger">*</span>
            </div>

            <a-select
              mode="multiple"
              size="default"
              placeholder="Pilih role"
              v-decorator="[
                'roles',
                {
                  // initialValue: null,
                  rules: [{ required: true, message: 'Pilih role Anda!' }],
                },
              ]"
            >
              <!-- <a-select-option value="" disabled selected>Pilih role</a-select-option> -->
              <a-select-option value="dokter">Dokter</a-select-option>
              <a-select-option value="perawat">Perawat</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="this.isDoctorRole()">
            <div class="h6 font-weight-bold">
              Spesialisasi (Layanan)<span class="text-danger">*</span>
            </div>

            <a-select
              size="default"
              placeholder="Pilih Spesialisasi"
              v-decorator="[
                'specialization_id',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Pilih spesialisasi Anda!' },
                  ],
                },
              ]"
            >
              <a-select-option value="" disabled selected
                >Pilih spesialisasi dokter</a-select-option
              >
              <a-select-option
                v-for="specialization in doctorSpecializations"
                :key="specialization.id"
                :value="specialization.id"
              >
                {{ specialization.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <div class="h6 font-weight-bold">
            Nomor Telepon Selular<span class="text-danger">*</span>
          </div>
          <div class="d-flex">
            <a-form-item class="col-xl-2">
              <a-select
                v-decorator="[
                  'phone_no_code',
                  {
                    initialValue: '+62',
                    rules: [
                      { required: true, message: 'Kode negara harus dipilih' },
                    ],
                  },
                ]"
                placeholder="Kode Negara"
                style="width: 100%"
              >
                <a-select-option
                  v-for="code in phoneCode"
                  :key="code.country"
                  :value="code.code"
                >
                  {{ code.code }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="col-xl-10">
              <a-input
                size="default"
                placeholder="0000000000"
                v-decorator="[
                  'phone_no',
                  {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Masukkan nomor telepon Anda!',
                      },
                      {
                        pattern: /^\d{5,}$/,
                        message: 'Nomor telepon tidak valid',
                      },
                    ],
                  },
                ]"
                autocomplete="tel"
              />
            </a-form-item>
          </div>

          <a-button
            size="large"
            class="text-center w-100"
            type="primary"
            shape="round"
            :class="$style.submitButton"
            :loading="isLoading"
            @click="handleNextSteps"
          >
            <strong class="h5">Daftar</strong>
          </a-button>

          <div class="text-left pt-2 mt-3">
            <span class="mr-2">Sudah punya akun?</span>
            <router-link to="/auth/login" class="kit__utils__link font-size-16"
              >Masuk sekarang</router-link
            >
          </div>
        </div>
      </a-form>
      <!-- <div>
        <span class="mr-1">By signing up, you agree to the</span>
        <a href="javascript: void(0);" class="kit__utils__link">Terms of Service</a> and
        <a href="javascript: void(0);" class="kit__utils__link">Privacy Policy</a>
      </div> -->
    </div>
  </div>
</template>
<script>
import { getAllSpecializations } from '@/services/axios/api/dokter'
import { getPhoneCode } from '@/services/axios/api/phoneCode'
import { mapState } from 'vuex'
import apiClient from '../../../../../services/axios'
import SimpleOtpInput from 'vue-simple-otp-input'
import { debounce } from 'lodash'
const regexCapitals = new RegExp(/^(?=.*[A-Z]).{1,32}$/)
const regexNumber = new RegExp(/^(?=.*\d).{1,32}$/)
const regexSymbol = new RegExp(/^(?=.*[(!@#$%^&*_\-)]).{1,32}$/)
// import Steps from './steps.vue'
export default {
  name: 'CuiRegister',
  components: {
    // Steps,
    SimpleOtpInput,
  },
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
    disabledPassword() {
      return (
        this.passwordCheck.capital &&
        this.passwordCheck.number &&
        this.passwordCheck.symbol &&
        this.modelRegis.password.length >= 8 &&
        this.passwordCheck.confirm
      )
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      fayankesList: [
        'Tempat Praktik Mandiri',
        'Puskesmas',
        'Klinik',
        'Rumah Sakit',
        'Apotek',
        'Laboratorium Kesehatan',
        'Balai',
        'Lainnya',
      ],
      selectedFayankes: null,
      roles: [],
      medicalFacilityType: null,
      medicalFacility: [
        { value: 1, label: 'Fasilitas Kesehatan 1' },
        { value: 2, label: 'Fasilitas Kesehatan 2' },
        { value: 3, label: 'Fasilitas Kesehatan 3' },
      ],
      doctorSpecializations: [],
      isLoading: false,
      phoneCode: [],
      steps: 1,
      mySteps: ['Email', 'Verifikasi Email', 'Kata Sandi', 'Profil Pengguna'],
      lazyCode: '',
      timer: null,
      timeLeft: 0,
      cooldownPeriod: 10,
      isExpiredTime: false,
      modelRegis: {
        email: '',
        password: '',
      },
      passwordCheck: {
        capital: false,
        character: false,
        number: false,
        symbol: false,
        confirm: false,
      },
      regexCapitals,
      // regexChar,
      regexNumber,
      regexSymbol,
    }
  },
  mounted() {
    // const gClientId = [
    //   '737348619545-tlrb4m5pbu3ateh4vjai7p7v9c3mq409.apps.googleusercontent.com',
    // ]
    // this.form.getFieldDecorator('email', {
    //   initialValue: 'shulthan.daffaul.iman@gmail.com',
    // })
    // this.form.setFieldsValue({ email: 'shulthan.daffaul.iman@gmail.com' })
    this.initializeGoogleOauth()
    this.getSpecializations()
    this.getPhoneCode()
  },
  methods: {
    initializeGoogleOauth() {
      const gClientId =
        '737348619545-tlrb4m5pbu3ateh4vjai7p7v9c3mq409.apps.googleusercontent.com'
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.handleCredentialResponse,
      })
      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        theme: 'outline', // atau 'filled_blue'
        size: 'large',
        text: 'signin_with', // bisa diubah sesuai preferensi
        shape: 'pill', // bisa diubah sesuai preferensi
      })
    },
    handleSubmit(e) {
      this.isLoading = true
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (values.phone_no && values.phone_no.startsWith('0')) {
          values.phone_no = values.phone_no.replace(/^0+/, '')
        }
        values.phone_no = values.phone_no_code + values.phone_no
        delete values.phone_no_code
        // this.roles = []
        // this.roles.push(values.roles)
        // values.roles = this.roles
        if (!err) {
          const res = await this.$store.dispatch('user/REGISTER', {
            payload: values,
          })
          console.log('Register Response:', res)
          if (res === false) {
            this.isLoading = false
          }
          // console.log(values)
        }
      })
    },
    handleFayankesTypeClick(value) {
      this.medicalFacilityType = value
      console.log('Selected Fayankes Type:', this.medicalFacilityType)
    },
    checkPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue('password')) {
        callback(new Error('Password tidak cocok!'))
      } else {
        callback()
      }
    },

    isDoctorRole() {
      return (
        this.form.getFieldValue('roles') &&
        this.form.getFieldValue('roles').includes('dokter')
      )
    },

    handleGoogleLogin() {
      if (this.$refs.googleLoginBtn.querySelector('div[role=button]')) {
        this.$refs.googleLoginBtn.querySelector('div[role=button]').click()
      } else {
        this.initializeGoogleOauth()
        this.$refs.googleLoginBtn.querySelector('div[role=button]').click()
      }

      // this.$refs.googleLoginBtn.click()
      // console.log(
      //   this.$refs.googleLoginBtn.querySelector('div[role=button]'),
      //   '<<',
      // )
    },

    handleCredentialResponse(response) {
      if (response) {
        // console.log(response.credential)
        console.log('response', response)
        this.$store.dispatch('user/LOGIN_GOOGLE', {
          payload: { ...response },
        })
      } else {
        this.initializeGoogleOauth()
      }
    },

    async getSpecializations() {
      try {
        const response = await getAllSpecializations()
        console.log(response.specializations)
        this.doctorSpecializations = response.specializations
      } catch (error) {
        console.log(error)
      }
    },

    async getPhoneCode() {
      try {
        const response = await getPhoneCode()
        console.log(response)
        this.phoneCode = response
      } catch (error) {
        console.log(error)
      }
    },
    async handleNextSteps(e) {
      // this.isLoading = true
      if (this.steps === 1) {
        const { data } = await apiClient.post('/api/auth/request-otp', {
          email: this.form.getFieldValue('email'),
        })
        console.log(data, '<<<')
        if (data.status === 'ok') {
          this.modelRegis.email = this.form.getFieldValue('email')
          this.steps = 2
          if (this.$refs.countdownTime) {
            this.$refs.countdownTime.start()
          }
        }
      } else if (this.steps === 2) {
        const { data } = await apiClient.post('/api/auth/verif-otp', {
          email: this.modelRegis.email,
          otp: this.lazyCode,
        })
        console.log(data, '<<<<')
        if (data.status === 'ok') {
          this.steps = 3
        } else {
          this.$notification.warning({
            description: data.status,
            message: data.title,
          })
        }
      } else if (this.steps === 3) {
        this.steps = 4
      } else if (this.steps === 4) {
        this.isLoading = true
        e.preventDefault()
        this.form.validateFields(async (err, values) => {
          values.email = this.modelRegis.email
          values.password = this.modelRegis.password
          if (values.phone_no && values.phone_no.startsWith('0')) {
            values.phone_no = values.phone_no.replace(/^0+/, '')
          }
          values.phone_no = values.phone_no_code + values.phone_no
          delete values.phone_no_code
          // this.roles = []
          // this.roles.push(values.roles)
          // values.roles = this.roles
          console.log(values, '<<<THIS VALUES')
          if (!err) {
            const res = await this.$store.dispatch('user/REGISTER', {
              payload: values,
            })
            console.log('Register Response:', res)
            if (res === false) {
              this.isLoading = false
            }
            // console.log(values)
          }
        })
      }
      // this.isLoading = false
    },
    async handleBackSteps() {
      if (this.steps === 2) {
        this.steps = 1
      } else if (this.steps === 3) {
        this.steps = 2
      } else if (this.steps === 4) {
        this.steps = 3
      }
    },
    transformCountDown(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value

        // uses singular form when the value is less than 2

        props[key] = `${digits}`
      })

      return props
    },
    handleEndCount() {
      this.isExpiredTime = true
    },
    handleStartCount() {},
    async handleTrySendCode(email) {
      const { data } = await apiClient.post('/api/auth/request-otp', {
        email: email,
      })
      if (data.status === 'ok') {
        this.isExpiredTime = false
      }
      console.log(email, '<<<<DISNI')
    },
    handleChangePassword: debounce(function (e) {
      this.checkRegex(e.target.value)
    }, 500),
    handleChangeConfirmPassword(e) {
      const pass = this.form.getFieldValue('password')
      if (pass === e.target.value) {
        this.passwordCheck.confirm = true
      } else {
        this.passwordCheck.confirm = false
      }
      this.modelRegis.confirmPassword = e.target.value
    },
    checkRegex(str) {
      // console.log(
      //   str.match(regexCapitals),
      //   '<<<cappital\n',
      //   str.match(regexNumber),
      //   '<<<number\n',
      //   str.match(regexSymbol),
      //   '<<<<simbol',
      // )
      this.modelRegis.password = str
      if (str.match(regexCapitals)) {
        this.passwordCheck.capital = true
      } else {
        this.passwordCheck.capital = false
      }
      if (str.match(regexNumber)) {
        this.passwordCheck.number = true
      } else {
        this.passwordCheck.number = false
      }
      if (str.match(regexSymbol)) {
        this.passwordCheck.symbol = true
      } else {
        this.passwordCheck.symbol = false
      }
    },
  },
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
.containerStepsRegister {
  width: 100%;
  // background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerItemStep {
  width: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
  // z-index: 99;
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 82%;
    background-color: #eaecf0;
    color: #eaecf0;
    z-index: 99;
    top: 12.5px;
    right: -52.5px;
    transform: translateY(-50%);
  }
}
.containerItemStepDone {
  &::after {
    // background-color: #eaecf0;
    // color: #eaecf0;
    background: linear-gradient(45deg, #006b61, #3ab9ad);
  }
}

.circleStep {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  border: 1px solid #eaecf0;
  background: white;
  &.done {
    background: linear-gradient(45deg, #006b61, #3ab9ad);
    border: none;
  }
}
.activeCircle {
  border: 1px solid #006b61;
  background-color: #c1ede9;
}
.dotActiveCircle {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: linear-gradient(45deg, #006b61, #3ab9ad);
}

/** OTP STYLE */
.containerOtp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}
:global(.hidden-otp-input) {
  display: none !important;
}
.otpInputStyle {
  width: 74px;
  height: 84px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  text-align: center;
}
@media (max-width: 767px) {
  .otpInputStyle {
    width: 37px;
    height: 42px;
  }
}
/** OTP STYLE */

.textResendCode {
  text-decoration: underline;
  cursor: pointer;
}
</style>
